import React, { Component } from 'react';
import { HeaderTile } from './HeaderTile';

export class HeaderTiles extends Component {
    render() {
        return (
            <div className="tiles">
                <div className="container">
                    <HeaderTile className="wind" icon="/images/icons/wind.svg" title="Wind" blurb="Wind power is one of the cheapest sources of large-scale energy and is one of Australia’s main sources of renewable energy. In Victoria, wind energy constitutes 12.2% of total electricity generation, up from 8.8% in 2018."/>
                    <HeaderTile className="solarfarm" icon="/images/icons/solarfarm.svg" title="Solar Farms" blurb="Solar farms typically use the same technology as rooftop solar panels and can generate thousands of megawatts of solar power. In 2020, large scale solar accounted for 2.9% of overall electricity generation in the state, up from 0.5% in 2018." />
                    <HeaderTile className="rooftopsolar" icon="/images/icons/rooftopsolar.svg" title="Rooftop Solar" blurb="Rooftop solar, or small-scale solar, generates electricity at the point of demand typically using solar photovoltaic panels. In 2020, small-scale solar (systems up to 100 kW) produced 3.7% of Victoria’s total electricity." />
                    <HeaderTile className="biomass" icon="/images/icons/biomass.svg" title="Biomass" blurb="Biomass is the organic material used to produce bioenergy, energy which is generated from waste sources derived from agricultural, forestry and municipal wastes. In Victoria in 2020, bioenergy accounted for 1.1% of total electricity generation." />
                    </div>
            </div>
        );
    }
}