import React, { useState } from 'react';
import { Routes, Route, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MainApp } from './components/MainApp';
import { Disclaimer } from './components/Disclaimer';
import { HeaderBackSoon } from './components/HeaderBackSoon';
import { LoginForm } from './components/LoginForm';

import { TempHelper } from './helpers/TempHelper';

const App = () => {
    //const displayName = App.name;

    let result = TempHelper.getFromStore();

    // could use a timer to test this periodically
    // unless we change the state / route this component may not rerender
    const [state, setState] = useState({ user: result.user, error: result.error });

    // can remove once public
    const onLogin = (username, password) => {
        if (TempHelper.va_l1d(username, password)) {
            const user = {
                username,
                password
            };
            setState({ user: user, error: null });
            TempHelper.addToStore(user);
        }
        else {
            alert('Invalid login');
        }
    };

    // can remove once public
    const logout = () => {
        setState({ user: null, error: null });
        TempHelper.addToStore(null);
    };

    const isValidDomain = () => {
        let s = window.location.hostname;
        return s.startsWith("ce.") || s.startsWith("renewable.remplan") || s === "localhost";
    }

    const showLogin = () => {
        return process.env.NODE_ENV === 'production' && !state.user;
    }

    // Match all url routes so MainApp can validate etc
    if (!isValidDomain())
        return (<div className="mainapp"><HeaderBackSoon /></div>);

    //if (showLogin())
    //    return (<LoginForm onLogin={onLogin} errors={state.error} />);

    return (
        <Routes>
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/:type?/:name?/:insight?" element={<MainAppWithRouter />} />
            <Route index element={<MainAppWithRouter />} />
        </Routes>
    );
};

export default App;

const MainAppWithRouter = (props) => {
    const params = useParams(); // .get("??")
    const navigate = useNavigate(); // navigate(url)
    const [searchParams,setSearchParams] = useSearchParams();

    return <MainApp {...props} routeParams={params} navigate={navigate} queryParams={searchParams} />;
};
/*
export const withRouter = (WrappedComponent) => (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const searchParams = useSearchParams();

    return <WrappedComponent {...props} routeParams={params} navigate={navigate} queryParams={searchParams[0]} />;
};
*/
/*
export default class App extends Component {
    displayName = App.name;

    constructor(props) {
        super(props);

        this.onLogin = this.onLogin.bind(this);
        let result = TempHelper.getFromStore();
        // could use a timer to test this periodically
        // unless we change the state / route this component may not rerender
        this.state = { user: result.user, error: result.error };
    }

    // can remove once public
    onLogin(username, password) {
        if (TempHelper.va_l1d(username, password)) {
            const user = {
                username,
                password
            };
            this.setState({ user: user, error: null });
            TempHelper.addToStore(user);
        }
        else {
            alert('Invalid login');
        }
    }

    // can remove once public
    logout() {
        this.setState({user: null, error: null});
        TempHelper.addToStore(null);
    }

    isValidDomain() {
        let s = window.location.hostname;
        return s.startsWith("ce.") || s.startsWith("renewable.remplan") || s === "localhost";
    }

    showLogin() {
        return process.env.NODE_ENV === 'production' && !this.state.user;
    }

    // Match all url routes so MainApp can validate etc
    render() {
        //if (!this.isValidDomain())
        //    return (<div className="mainapp"><HeaderBackSoon /></div>);

        //if (this.showLogin())
        //    return (<LoginForm onLogin={this.onLogin} errors={this.state.error} />);
        
        return (
            <div>Hello
            <Routes>
                    <Route path="/disclaimer" element={<Disclaimer/>} />
                    <Route path="/:type?/:name?/:insight?" element={<MainApp />} />
                    <Route index element={<MainApp/>} />
                </Routes>
            </div>
        );
    }
}
*/