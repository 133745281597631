// consider using https://github.com/JedWatson/classnames

import React, { Component } from 'react';
export class MapControlButton extends Component {

/*  constructor(props) {
    super(props);
  }*/

    render() {
        return (
            <div className={"mapzone" + (!this.props.isDisabled && this.props.isChecked ? " checked":"") + (this.props.isDisabled ? " disabled":"")} onClick={(e) => !this.props.isDisabled && this.props.onClick(this.props.tag)}>{this.props.title}</div>
    );
  }
}
