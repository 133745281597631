import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Header } from './Header';
import { Banner } from './Banner';
import { Footer } from './Footer';

export class Disclaimer extends Component {
    render() {
        return (
            <div className="disclaimer">
                <Header title={
                    <h1>
                        <span className="home">
                            <Link to="/">Home</Link>
                        </span>
                        Disclaimer
                    </h1>
                } blurb={
                    <div>
                        <p>
                            All economic and demographic figures and analysis presented on this site are based on data sourced from the Australia Bureau of Statistics (ABS), and other Australian government agencies. Using ABS datasets, the regional economic modelling software REMPLAN, developed by REMPLAN has been applied to generate industrial economic data estimates.   Data and insights regarding the capital costs and operational revenues of renewable energy facilities has been sourced from Green Energy Markets, MRA Consulting, the Central Victorian Greenhouse Alliance and the Department of Environment, Land, Water and Planning.   This document is provided in good faith with every effort made to provide accurate data and apply comprehensive knowledge. However, REMPLAN does not guarantee the accuracy of data nor the conclusions drawn from this information. A decision to pursue any suggestions mentioned in the report is wholly the responsibility of the party concerned.  REMPLAN advises any party to conduct detailed feasibility studies and seek professional advice before proceeding with any action and accept no responsibility for the consequences of pursuing any of the findings or actions discussed in the document.
                        </p>

                        <div className="container">
                            <p className="b-remplan">
                                <a target="link" href="https://www.remplan.com.au/"><img alt="remplan" src="/images/logos/remplan.png" /></a>
                            </p>

                            <p className="b-dashboard">
                                <Link to="/">Return to the dashboard</Link>
                            </p>
                        </div>
                    </div>
                } />
                <Banner />

                <Footer />
            </div>
        );
    }
}