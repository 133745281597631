import React, { Component } from 'react';
import { LegendItem } from './LegendItem';

export class Legends extends Component {

    render() {
        return (
            <div className="legend" onClick={this.props.onLegendClick}>
                {this.props.library.Overlays.map((x) => !!this.props.overlayLayerIds[x.id] && (<LegendItem key={x.id} item={x} />))}
            </div>
        );
    }
}