import React, { Component } from 'react';

export class Header extends Component {
    render() {
        return (
            <div className="header">
                <div className="mask">
                    <div className="container">
                        {
                            this.props.title
                                ? this.props.title
                                : <h1>Renewable Energy Transition<br />Economic Modelling Tool</h1>
                        }
                        <div className="blurb">
                            {
                                this.props.blurb
                                    ? this.props.blurb
                                    : <p>This resource supports the Regional Renewable Energy Roadmap which sets out the Victorian Government's plan to attract renewable energy investment and jobs.</p>
                            }
                            <p className="published">Published: October 2023</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}