import React, { Component } from 'react';

export class About extends Component {
    render() {
        return (
            <div className="about">
                <div className="container">
                    <h5>About this site  –</h5>
                    <p>Collaboration with communities throughout the State has been central to the preparation of the Regional Renewable Energy Roadmap.  An important aspect of this has been to explore the key opportunities for each region to contribute to the State’s renewable energy target.   This site provides a range of insights and tools for communities to understand the possibilities for renewable energy in their region.</p>
                </div>
            </div>
        );
    }
}