export class Config {
    static GoogleMapsAPIKey = process.env.NODE_ENV !== 'production'
        ? "AIzaSyDOwHmI5-RQiEmOsIEpAVgBHuEfqP6Y-DM" // in-house testing
        : "AIzaSyCdDAGIjqvdtVmZZOpLRn867fuVc32pIMQ"; // energy dashboard

    // must be valid JSON array with [{featureType:xxx,...},{featureType:xxx,...}...]
    static GoogleMapsJSStyle = '[{"featureType":"administrative","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"visibility":"on"},{"color":"#000000"},{"weight":"2.76"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#404040"}]},{"featureType":"administrative","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#f0f0f0"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"visibility":"on"},{"color":"#d9d9d9"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"on"},{"saturation":"0"},{"lightness":"0"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#e8e8f0"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#404040"}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]}]'

    // These are from the spreadsheet
    static REPCapMin = 0;
    static REPCapMax = 1;
    static REPSolarMin = 1;
    static REPSolarMax = 9;
    static REPWindMin = 5.73;
    static REPWindMax = 8.51

    static TransLineSizeMin = 1;
    static TransLineSizeMax = 8;
    static SystemStrengthMin = 0;
    static SystemStrengthMax = 17.6364;
    static DistToTransMin = -80;
    static DistToTransMax = -5;
    static BDoubleRouteMin = 0.4048;
    static BDoubleRouteMax = 9.92879;

    // Victoria
    static MapBounds = { n: -33.978955, e: 150.012817, s: - 39.181175, w: 140.961456 };
}