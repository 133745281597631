import React, { Component } from 'react';
import { Chart } from 'chart.js';
import { NumberFormatting } from '../helpers/NumberFormatting';

// Not used
export class GRPTrendChart extends Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this._chart = new Chart(this.chartRef.current, {
            type: 'bar', // to force ticks away from end of axis
            options: {
                tooltips: {
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data.datasets[tooltipItem[0].datasetIndex].label;
                        },
                        label: function (tooltipItem, data) {
                            return tooltipItem.xLabel + ": $" + NumberFormatting.FmtDigitGroups(tooltipItem.yLabel / 1e9, 3) + " B";
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    y: {
                        suggestedMin: 0,
                        ticks: {
                            callback: function (value, index, values) {
                                return '$' + NumberFormatting.FmtDigitGroups(value / 1e9, 2) + ' B';
                            }
                        }
                    },
                    x: {
                        stacked: false,
                        grid: { display: false }
                    }
                }
            },
            data: this.getChartData()
        });
    }

    getChartData() {
        let data = this.props.region.grp;

        return {
            labels: data.map((v,i) => this.props.region.grpFirstYear+i),
            datasets: [{
                label: 'Gross Regional Product',
                type: 'line',
                fill: false,
                backgroundColor: '#364955',
                borderColor: '#364955',
                pointRadius: 6,
                data: data
            }]
        };
    }

    updateChart() {
        if (this._chart) {
            this._chart.data = this.getChartData();
            this._chart.update();
        }
    }

    render() {
        let height = "15em";
        let style = { minHeight: height, height: height };

        this.updateChart();

        return <div className="grptrendchart">
            <h5>Gross Regional Product (Real 2021 Prices)</h5>
            <div className="jschartContainer" style={style}>
                <canvas ref={this.chartRef} />
            </div>
        </div>;
    }
}

/* chart js expected data
{
    labels: ['A', 'B', 'C', ...],
        datasets: [{
            label: 'My data',
            data: [10, 20, 30, ...],
            backgroundColor: '#112233'
        }]
}
*/