export class WMSTileLayer {
    tileWidth = 512;
    tileHeight = 512;
    map = null;

    constructor(url, opacity) {
        this.mapType = new window.google.maps.ImageMapType({
            getTileUrl: function (coord, zoom) {
                let bbox = this.tileCoordsToBBox(this.map, coord, zoom, this.tileWidth, this.tileHeight);
                let url2 = url + "&bbox=" + bbox.join(",") + "&width=" + this.tileWidth + "&height=" + this.tileHeight;
                return url2;
            }.bind(this),
            tileSize: new window.google.maps.Size(this.tileWidth, this.tileHeight),
            opacity: opacity
        });
    }

    // utility function
    tileCoordsToBBox(map, coord, zoom, tileWidth, tileHeight) {
        var proj = map.getProjection();

        // scale is because the number of tiles shown at each zoom level double.
        var scale = Math.pow(2, zoom);

        // A point is created for the north-east and south-west corners, calculated
        // by taking the tile coord and multiplying it by the tile's width and the map's scale.
        var ne = proj.fromPointToLatLng(new window.google.maps.Point((coord.x + 1) * this.tileWidth / scale, coord.y * this.tileHeight / scale));
        var sw = proj.fromPointToLatLng(new window.google.maps.Point(coord.x * this.tileWidth / scale, (coord.y + 1) * this.tileHeight / scale));

        return [sw.lng(), sw.lat(), ne.lng(), ne.lat()];
    }

    setMap(map) {
        if (map !== this.map) {
            if (this.map) {
                for (var i = this.map.overlayMapTypes.getLength() - 1; i >= 0; --i)
                    if (this.map.overlayMapTypes.getAt(i) === this.mapType)
                        this.map.overlayMapTypes.removeAt(i);
            }
            this.map = map;
            if (this.map) {
                this.map.overlayMapTypes.push(this.mapType);
            }
        }
    }
}

export class WMS3857TileLayer {
    tileWidth = 256;
    tileHeight = 256;
    map = null;

    static ExtentMin = -Math.PI * 6378137;
    static ExtentMax = Math.PI * 6378137;
    static ExtentRange = 2 * Math.PI * 6378137;

    constructor(url, opacity) {
        this.mapType = new window.google.maps.ImageMapType({
            getTileUrl: function (coord, zoom) {
                let tileSize = WMS3857TileLayer.ExtentRange >> zoom;
                let bbox = [
                    WMS3857TileLayer.ExtentMin + coord.x * tileSize,
                    WMS3857TileLayer.ExtentMax - (coord.y + 1) * tileSize,
                    WMS3857TileLayer.ExtentMin + (coord.x + 1) * tileSize,
                    WMS3857TileLayer.ExtentMax - coord.y * tileSize];
                let url2 = url + "?bbox=" + bbox.join(",") + "&width=" + this.tileWidth + "&height=" + this.tileHeight;
                return url2;
            }.bind(this),
            tileSize: new window.google.maps.Size(this.tileWidth, this.tileHeight),
            opacity: opacity
        });
    }

    setMap(map) {
        if (map !== this.map) {
            if (this.map) {
                for (var i = this.map.overlayMapTypes.getLength() - 1; i >= 0; --i)
                    if (this.map.overlayMapTypes.getAt(i) === this.mapType)
                        this.map.overlayMapTypes.removeAt(i);
            }
            this.map = map;
            if (this.map) {
                this.map.overlayMapTypes.push(this.mapType);
            }
        }
    }
}