import React, { Component } from 'react';

//import { RegionInsightNav } from './RegionInsightNav';
import { RenewableEnergyPotential } from './RenewableEnergyPotential';
import { Infrastructure } from './Infrastructure';
import { Environment } from './Environment';
import { EconomyDemography } from './EconomyDemography';
import { EconomicImpact } from './EconomicImpact';
import { RegionBlurb } from './RegionBlurb';
import { RegionInsightButton } from './RegionInsightButton';

import { InsightType } from '../constants/InsightType';

export class RegionInsights extends Component {
    /*    constructor(props) {
            super(props);
        }*/

    render() {
        if (!this.props.region) return null;

        return (
            <div className="regioninsights">
                <div className="container">
                    <h3>{this.props.region.name} Regional Insights</h3>
                    {this.props.region.urlReport && <a className="download" href={process.env.PUBLIC_URL + this.props.region.urlReport} target="blank">Download PDF report for this area <img alt="" src="/images/icons/savepdf.png"/></a>}
                </div>

                <div className="container">
                    <div className="scroller">
                        <RegionInsightButton tag={InsightType.REP} isChecked={this.props.insight === InsightType.REP} title="Renewable Energy Potential" icon="/images/icons/renewableenergypotential.svg" onClick={this.props.onInsight} />
                        <RegionInsightButton tag={InsightType.Infrastructure} isChecked={this.props.insight === InsightType.Infrastructure} title="Infrastructure" icon="/images/icons/infrastructure.svg" onClick={this.props.onInsight} />
                        <RegionInsightButton tag={InsightType.Environment} isChecked={this.props.insight === InsightType.Environment} title="Environment" icon="/images/icons/environment.svg" onClick={this.props.onInsight} />
                        <RegionInsightButton tag={InsightType.Economy} isChecked={this.props.insight === InsightType.Economy} title="Economy & Demography" icon="/images/icons/economy demography.svg" onClick={this.props.onInsight} />
                        <RegionInsightButton tag={InsightType.Impact} isChecked={this.props.insight === InsightType.Impact} title="Economic Impact Tool" icon="/images/icons/economicimpact.svg" onClick={this.props.onInsight} />
                    </div>
                </div>

                {this.props.insight === InsightType.Infrastructure && <Infrastructure region={this.props.region} />}
                {this.props.insight === InsightType.Environment && <Environment region={this.props.region} />}
                {this.props.insight === InsightType.Economy && <EconomyDemography region={this.props.region} />}
                {this.props.insight === InsightType.Impact && <EconomicImpact region={this.props.region} impact={this.props.impact} onImpact={this.props.onImpact} />}
                {this.props.insight === InsightType.REP && <RenewableEnergyPotential region={this.props.region} />}

                <RegionBlurb region={this.props.region} />
            </div>
        );
    }
}