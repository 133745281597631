import React, { Component } from 'react';
export class MapControlsExpand extends Component {

//  constructor(props) {
//      super(props);
//  }

    render() {
        return (
            <div className={"mapexpand" + (!this.props.checked ? " checked" : "")} onClick={this.props.onClick}/>
    );
  }
}
