import React, { Component } from 'react';
import { Chart, BarController, LinearScale, CategoryScale, BarElement, Tooltip } from 'chart.js';
import { NumberFormatting } from '../helpers/NumberFormatting';
import { ChartHelper } from '../helpers/ChartHelper';

Chart.register(BarController, LinearScale, CategoryScale, BarElement, Tooltip);

export class ForecastPopulationChart extends Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this._gradient = ChartHelper.MakeVertGradient(this.chartRef.current);

        this._chart = new Chart(this.chartRef.current, {
            type: 'bar',
            options: {
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (context) => context[0].dataset.label,
                            label: (context) => context.label + ": " + NumberFormatting.FmtDigitGroups(context.parsed.y, 0)
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    y: {
                        ticks: {
                            suggestedMin: 0,
                            callback: function (value, index, values) {
                                return NumberFormatting.FmtDigitGroups(value, 0);
                            }
                        }
                    },
                    x: {
                        stacked: false,
                        grid: { display: false },
                        
                    }
                },
                maxBarThickness: 28,
            },
            data: this.getChartData()
        });
    }

    getChartData() {
        let data = this.props.region.forecastPop.map((v, i) => ({
            year: this.props.region.forecastFirstYear + i * this.props.region.forecastStepYear,
            pop: v}));

        return {
            labels: data.map(v => v.year),
            datasets: [{
                label: 'People',
                data: data.map(v => v.pop),
                backgroundColor: this._gradient,
            }]
        };
    }

    updateChart() {
        if (this._chart) {
            this._chart.data = this.getChartData();
            this._chart.update();
        }
    }

    render() {
        let height = "15em";
        let style = { minHeight: height, height: height };

        this.updateChart();

        return <div className="forecastpopulationchart">
            <h5>Forecast Population</h5>
            <div className="jschartContainer" style={style}>
                <canvas ref={this.chartRef} />
            </div>
        </div>

    }
}
