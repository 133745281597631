import React, { Component } from 'react';
import { Chart, BarController, LinearScale, CategoryScale, BarElement, Tooltip } from 'chart.js';
import { NumberFormatting } from '../helpers/NumberFormatting';
import { ChartShowAll } from './ChartShowAll';
import { ChartTypeToggle } from './ChartTypeToggle';
import { ChartHelper } from '../helpers/ChartHelper';

Chart.register(BarController, LinearScale, CategoryScale, BarElement, Tooltip);

export class OutputValueAddedChart extends Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();

        this.onShowAll = this.onShowAll.bind(this);
        this.onType = this.onType.bind(this);

        this.state = { showAll: false, valueadded: false };
    }

    componentDidMount() {
        this._gradient = ChartHelper.MakeHorzGradient(this.chartRef.current);

        this._chart = new Chart(this.chartRef.current, {
            type: 'bar',
            options: {
                indexAxis: 'y',
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => this.state.valueadded
                                ? "Value Added: $" + NumberFormatting.FmtDigitGroups(context.parsed.x / 1e6, 3) + " M"
                                : "Output: $" + NumberFormatting.FmtDigitGroups(context.parsed.x / 1e6, 3) + " M"
                        }
                    },
                },
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    x: {
                        beginAtZero: true,
                        suggestedMin: 0,
                        ticks: {
                            callback: function (value, index, values) {
                                return NumberFormatting.FmtDigitGroups( value / 1e6, 0);
                            }
                        }
                    },
                    y: {
                        grid: { display: false },
                        ticks: { autoSkip: false }
                    }
                },
                maxBarThickness: 11
            },
            data: this.getChartData()
        });
    }

    onShowAll() {
        this.setState({ showAll: !this.state.showAll });
    }

    onType() {
        this.setState({ valueadded: !this.state.valueadded });
    }

    getChartData() {
        let data = this.props.region.sectorEmployment.map((v, i) => (
            {
                name: this.props.region.sectorNames[i],
                value: this.state.valueadded ? this.props.region.sectorValueAdded[i] : this.props.region.sectorOutput[i]
            }));
        data.sort((a, b) => b.value - a.value);
        data.length = this.getNumRows();

        return {
            labels: data.map(i => i.name),
            datasets: [{
                label: this.state.valueadded ? "Value added" : "Output",
                data: data.map(i => i.value),
                backgroundColor: this._gradient
            }]
        };
    }

    updateChart() {
        if (this._chart) {
            this._chart.data = this.getChartData();
            this._chart.update();
        }
    }

    getNumRows() {
        return this.state.showAll ? this.props.region.sectorOutput.length : 7;
    }

    render() {
        let height = (this.getNumRows() + 3) + "em";
        let style = { minHeight: height, height: height };

        this.updateChart();

        return <div className="outputvalueaddedchart">
            <h5>Output / Value Added $M</h5>
            <span className="toggle">Output <ChartTypeToggle checked={this.state.valueadded} onType={this.onType} /> Value Added</span>
            <div className="jschartContainer" style={style}>
                <canvas ref={this.chartRef} />
                <ChartShowAll showAll={this.state.showAll} onShowAll={this.onShowAll} />
            </div>
        </div>;
    }
}
