import React, { Component } from 'react';
import { Chart, BarController, LinearScale, CategoryScale, BarElement, Tooltip } from 'chart.js';
import { NumberFormatting } from '../helpers/NumberFormatting';
import { ChartHelper } from '../helpers/ChartHelper';

Chart.register(BarController, LinearScale, CategoryScale, BarElement, Tooltip);

export class ChangeInEmploymentChart extends Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this._gradient = ChartHelper.MakeHorzGradient(this.chartRef.current);

        this._chart = new Chart(this.chartRef.current, {
            type: 'bar',
            options: {
                indexAxis: 'y',
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => "Change in % of total: " + NumberFormatting.FmtDigitGroups(context.parsed.x, 1)+"%"
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    x: {
                        beginAtZero: true,
                        ticks: {
                            callback: function (value, index, values) {
                                return value + '%';
                            }
                        }
                    },
                    y: {
                        grid: { display: false },
                        ticks: { autoSkip: false }
                    }
                },
                maxBarThickness: 11
            },
            data: this.getChartData()
        });

    }

    getMaxMinValues(data, maxRows) {
        // Pick off values from each end until we have enough
        let s = 0; let e = data.length;
        let numValues = 0;
        while (numValues < maxRows && data[s].value > 0 && data[e - 1].value < 0) {
            if (data[s].value > 0) {
                s++;
                numValues++;
            }
            if (data[e - 1].value < 0) {
                e--;
                numValues++;
            }
        }

        let data2 = [];
        for (var i = 0; i < data.length; i++)
            if (i < s || i >= e)
                data2.push(data[i]);

        return data2;
    }

    getChartData() {
        let yearIndex = this.props.region.sectorEmploymentYears.length - 1;
        let data = this.props.region.sectorEmployment.map((v, i) => (
            {
                name: this.props.region.sectorNames[i],
                value: (this.props.region.totalEmployment[yearIndex]> 0
                    ? this.props.region.sectorEmployment[i][yearIndex] / this.props.region.totalEmployment[yearIndex] * 100
                    : 0)
                    -
                    (this.props.region.totalEmployment[yearIndex-1] > 0
                    ? this.props.region.sectorEmployment[i][yearIndex - 1] / this.props.region.totalEmployment[yearIndex-1] * 100
                    : 0)
            }));
        data.sort((a, b) => b.value - a.value);

        data = this.getMaxMinValues(data, this.getNumRows());

        return {
            labels: data.map(i => i.name),
            datasets: [{
                label: 'Change in %',
                data: data.map(i => i.value),
                backgroundColor: this._gradient
            }]
        };
    }

    updateChart() {
        if (this._chart) {
            this._chart.data = this.getChartData();
            this._chart.update();
        }
    }

    getNumRows() {
        return 7;
    }

    render() {
        let height = "10em";
        let style = { minHeight: height, height: height };
        let yearIndex = this.props.region.sectorEmploymentYears.length - 1;
        
        this.updateChart();

        return <div className="changeinemploymentchart">
            <h5>Change in % share of Employment from {this.props.region.sectorEmploymentYears[yearIndex - 1]} to {this.props.region.sectorEmploymentYears[yearIndex]}</h5>
            <div className="jschartContainer" style={style}>
                <canvas ref={this.chartRef} />
            </div>
        </div>;
    }
}

/* chart js expected data
{
    labels: ['A', 'B', 'C', ...],
        datasets: [{
            label: 'My data',
            data: [10, 20, 30, ...],
            backgroundColor: '#112233'
        }]
}
*/