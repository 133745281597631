import React, { Component } from 'react';
import { Chart, BarController, LinearScale, CategoryScale, BarElement, Tooltip } from 'chart.js';
import { NumberFormatting } from '../helpers/NumberFormatting';
import { ChartShowAll } from './ChartShowAll';
import { ChartHelper } from '../helpers/ChartHelper';

Chart.register(BarController, LinearScale, CategoryScale, BarElement, Tooltip);

export class EmploymentByIndustryChart extends Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();

        this.onShowAll = this.onShowAll.bind(this);

        this.state = { showAll: false };
    }

    componentDidMount() {
        this._gradient = ChartHelper.MakeHorzGradient(this.chartRef.current);

        this._chart = new Chart(this.chartRef.current, {
            type: 'bar',
            options: {
                indexAxis: 'y',
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => NumberFormatting.FmtDigitGroups(context.parsed.x, 0) + " jobs"
                        }
                    },
                },
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    x: {
                        beginAtZero: true,
                        ticks: {
                            callback: function (value, index, values) {
                                return NumberFormatting.FmtDigitGroups( value, 0 );
                            }
                        }
                    },
                    y: {                        
                        grid: { display: false },
                        ticks: { autoSkip: false }
                    }
                },
                maxBarThickness: 11
            },
            data: this.getChartData()
        });
    }

    onShowAll() {
        this.setState({ showAll: !this.state.showAll });
    }

    getChartData() {
        let data = this.props.region.sectorEmployment.map((v, i) => ({ name: this.props.region.sectorNames[i], value: v[v.length-1] }));
        data.sort((a, b) => b.value - a.value);
        data.length = this.getNumRows();

        return {
            labels: data.map(i => i.name),
            datasets: [{
                label: 'Jobs',
                data: data.map(i => i.value),
                backgroundColor: this._gradient
            }]
        };
    }

    updateChart() {
        if (this._chart) {
            this._chart.data = this.getChartData();
            this._chart.update();
        }
    }

    getNumRows() {
        return this.state.showAll ? this.props.region.sectorEmployment.length : 7;
    }

    render() {
        let height = (this.getNumRows() + 3) + "em";
        let style = { minHeight: height, height: height };

        this.updateChart();

        return <div className="employmentbyindustrychart">
            <h5>Employment by Industry</h5>
            <div className="jschartContainer" style={style}>
                <canvas ref={this.chartRef} />
                <ChartShowAll showAll={this.state.showAll} onShowAll={this.onShowAll} />
            </div>
        </div>

    }
}
