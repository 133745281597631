import React, { Component } from 'react';
import { MapControlButton } from './MapControlButton';
import { MapControlsExpand } from './MapControlsExpand';

import { RegionType } from '../constants/RegionType';

export class MapOverlayZones extends Component {
    constructor(props) {
        super(props);

        this.state = { expanded: true };

        this.onExpand = this.onExpand.bind(this);
    }

    onExpand(e) {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        return (
            <div className="mapoverlays">
                <MapControlsExpand checked={this.state.expanded} onClick={this.onExpand} />
                <h3>2: Map Overlay Controls</h3>
                <div className={"pane" + (this.state.expanded ? " expanded" : " collapsed")}>
                    <MapControlButton title="Overlay Local Government Areas" isDisabled={this.props.type === RegionType.LGA} isChecked={this.props.overlayType === RegionType.LGA} tag={RegionType.LGA} onClick={this.props.onOverlayType}/>
                    <MapControlButton title="Overlay State Government Regions" isDisabled={this.props.type === RegionType.StateRegion} isChecked={this.props.overlayType === RegionType.StateRegion} tag={RegionType.StateRegion} onClick={this.props.onOverlayType}/>
                    <MapControlButton title="Overlay Renewable Energy Zones" isDisabled={this.props.type === RegionType.REZ} isChecked={this.props.overlayType === RegionType.REZ} tag={RegionType.REZ} onClick={this.props.onOverlayType}/>
                </div>
            </div>
        );
    }
}