import React, { Component } from 'react';
import { NumberFormatting } from '../helpers/NumberFormatting';
import { RegionInsightInfoGraphic } from './RegionInsightInfoGraphic';

export class Environment extends Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        if (this.props.region === null) return null;

        return (
            <div className="environment">
                <h3>Environment</h3>
                <div className="container">
                    <RegionInsightInfoGraphic title="Total Land Area" value={NumberFormatting.FmtDigitGroups(this.props.region.landAreaHa, 0) + "ha"} icon="/images/icons/totalarea.svg" />
                    <RegionInsightInfoGraphic title="Dwelling density" value={NumberFormatting.FmtDigitGroups(this.props.region.dwellingsPerHa, 2) + "/ha"} icon="/images/icons/dwellingdensity.svg" />
                    <RegionInsightInfoGraphic title="Population density" value={NumberFormatting.FmtDigitGroups(this.props.region.populationPerHa, 2) + "/ha"} icon="/images/icons/populationdensity.svg" />
                    <RegionInsightInfoGraphic title="Area of crown land/reserves" value={NumberFormatting.FmtSafePercent(this.props.region.crownLandHa, this.props.region.landAreaHa, 1)} icon="/images/icons/crownlandarea.svg" />
                    <RegionInsightInfoGraphic title="Area under broad acre production" value={NumberFormatting.FmtSafePercent(this.props.region.broadAcreAgProdHa, this.props.region.landAreaHa, 1)} icon="/images/icons/broadacrearea.svg" />
                    {false && <RegionInsightInfoGraphic title="Area under high value production" value={NumberFormatting.FmtSafePercent(this.props.region.highProdAgHa, this.props.region.landAreaHa, 1)} icon="/images/icons/highvaluearea.svg" />}
                    <RegionInsightInfoGraphic title="Area covered by forestry" value={NumberFormatting.FmtSafePercent(this.props.region.forestryHa, this.props.region.landAreaHa, 1)} icon="/images/icons/forestryarea.svg" />
                    <div className="source">
                        <p>Based on data sourced from:</p>
                        <p>REMPLAN Community; and the Department of Agriculture (ABARES).</p>
                    </div>
                </div>
            </div>
        );
    }
}