import React, { Component } from 'react';

export class RegionInsightInfoGraphic extends Component {
    render() {
        return (
            <div className="regioninsightinfographic">
                <h5>{this.props.title}</h5>
                <img alt="Icon" src={this.props.icon} />
                <p>{this.props.value}</p>
            </div>
        );
    }
}