import React from 'react';
import ReactDOMServer from 'react-dom/server';

//#region -- Info Window --

function getInfoWindowContent(zone) {
    const keyIdxMap = zone.layer.props
        .reduce((map, p, i) => {
            map[p] = i;

            return map;
        }, {});
    const type = zone.layer.geogId;

    let result;

    switch (type) {
        case 'windfarms':
            result = getWindfarmContent(keyIdxMap, zone);
            break;
        case 'solarfarms':
            result = getSolarfarmContent(keyIdxMap, zone);
            break;
        case 'bioenergy':
            result = getBioenergyContent(keyIdxMap, zone);
            break;
        default:
            result = <React.Fragment>''</React.Fragment>;
            break;
    }

    return result;
}

function getWindfarmContent(keyIdxMap, zone) {
    /**
     * Possible keys;
     * Name, Status, LGA, Location, Proponent, SizeMW, Tunrbines, Tip Height, Hub Height, Rotor Diameter, AreaHA
     */
    const areaDetails = [
        buildInfoLine(keyIdxMap['AreaHA'], zone, (val) => `Area: ${val}ha`),
        buildInfoLine(keyIdxMap['Location'], zone, (val) => `Location: ${val}`),
        buildInfoLine(keyIdxMap['LGA'], zone, (val) => `LGA: ${val}`)
    ].filter(x => !!x);
    const turbineDetails = [
        buildInfoLine(keyIdxMap['Turbines'], zone, (val) => `How many turbines: ${val}`),
        buildInfoLine(keyIdxMap['SizeMW'], zone, (val) => `Power generation: ${val} MW`),
        buildInfoLine(keyIdxMap['Tip Height'], zone, (val) => `Turbine tip height: ${val}m`),
        buildInfoLine(keyIdxMap['Hub Height'], zone, (val) => `Hub height: ${val}m`),
        buildInfoLine(keyIdxMap['Rotor Diameter'], zone, (val) => `Rotor diameter: ${val}m`)
    ].filter(x => !!x);

    return <div id="ce-iw">
        <h5>{zone.props[keyIdxMap['Name']]}</h5>
        
        <p>
            Status: {getStatusDesc(zone.props[keyIdxMap['Status']])}<br />
            {buildInfoLine(keyIdxMap['Proponent'], zone, (val) => `Proponent: ${val}`)}
        </p>
        {areaDetails.length > 0 && <p><strong>Area information</strong><br />{areaDetails.map(x => <React.Fragment>{x}<br /></React.Fragment>)}</p>}
        {turbineDetails.length > 0 && <p><strong>Turbine details</strong><br />{turbineDetails.map(x => <React.Fragment>{x}<br /></React.Fragment>)}</p>}
    </div>;
}

function getSolarfarmContent(keyIdxMap, zone) {
    /**
     * Possible keys;
     * Name, Status, LGA, Location, Proponent, Num Panels, AreaHa, SizeMW(*)
     * 
     * (*) not available in data yet but supported 
     */
    const areaDetails = [
        buildInfoLine(keyIdxMap['AreaHa'], zone, (val) => `Area: ${val}ha`),
        buildInfoLine(keyIdxMap['Location'], zone, (val) => `Location: ${val}`),
        buildInfoLine(keyIdxMap['LGA'], zone, (val) => `LGA: ${val}`)
    ].filter(x => !!x);
    const panelDetails = [
        buildInfoLine(keyIdxMap['Num Panels'], zone, (val) => `How many panels: ${val}`),
        buildInfoLine(keyIdxMap['SizeMW'], zone, (val) => `Power generation: ${val} MW`)
    ].filter(x => !!x);

    return <div id="ce-iw">
        <h5>{zone.props[keyIdxMap['Name']]}</h5>
        
        <p>
            Status: {getStatusDesc(zone.props[keyIdxMap['Status']])}<br />
            {buildInfoLine(keyIdxMap['Proponent'], zone, (val) => `Proponent: ${val}`)}
        </p>
        {areaDetails.length > 0 && <p><strong>Area information</strong><br />{areaDetails.map(x => <React.Fragment>{x}<br /></React.Fragment>)}</p>}
        {panelDetails.length > 0 && <p><strong>Panel details</strong><br />{panelDetails.map(x => <React.Fragment>{x}<br /></React.Fragment>)}</p>}
    </div>;
}

function getBioenergyContent(keyIdxMap, zone) {
    /**
     * Possible keys;
     * Name, SizeMW, Participant, Fuel Prim, Fuel Desc, Tech
     */
    const plantDetails = [
        buildInfoLine(keyIdxMap['SizeMW'], zone, (val) => `Power generation: ${val} MW`),
        buildInfoLine(keyIdxMap['Fuel Prim'], zone, (val) =>
            zone.props[keyIdxMap['Fuel Desc']]
                ? `Fuel: ${val} (${buildInfoLine(keyIdxMap['Fuel Desc'], zone, (val) => `${val}`)})`
                : `Fuel: ${val}`),
        buildInfoLine(keyIdxMap['Tech'], zone, (val) => `Technology: ${val}`)
    ].filter(x => !!x);

    return <div id="ce-iw">
        <h5>{zone.props[keyIdxMap['Name']]}</h5>
        {zone.props[keyIdxMap['Comment']] && <p class="comment">{zone.props[keyIdxMap['Comment']]}</p>}
        <p>
            {buildInfoLine(keyIdxMap['Participant'], zone, (val) => `Participant: ${val}`)}
        </p>
        {plantDetails.length > 0 && <p><strong>Plant information</strong><br />{plantDetails.map(x => <React.Fragment>{x}<br /></React.Fragment>)}</p>}
    </div>;
}

function buildInfoLine(idx, zone, tplFn) {
    const val = typeof(idx) === 'number' && zone.props[idx];

    if (val) {
        return tplFn(val);
    }

    return '';
}

function getStatusDesc(status) {
    switch (status) {
        case 1:
            return 'Approved';
        case 2:
            return 'Under Construction';
        case 3:
            return 'Operating';
        default:
            return 'N/A';
    }
}

//#endregion -- InfoWindow --

export class MapOverlayLibrary {
    // Cant use window.google.maps until script is loaded, so we defer this to here
    // Called by MapService.setMap
    initIconAnchors() {
        this.SystemStrength.styles[0].icon.anchor = new window.google.maps.Point(16, 16);
        this.SystemStrength.styles[1].icon.anchor = new window.google.maps.Point(16, 16);
        this.SystemStrength.styles[2].icon.anchor = new window.google.maps.Point(16, 16);
        this.SystemStrength.styles[3].icon.anchor = new window.google.maps.Point(16, 16);
        this.SystemStrength.styles[4].icon.anchor = new window.google.maps.Point(16, 16);
        this.SystemStrength.styles[5].icon.anchor = new window.google.maps.Point(16, 16);
        this.SystemStrength.styles[6].icon.anchor = new window.google.maps.Point(16, 16);
    }

    PrimaryStyles = [
        { // roadmap
            strokeColor: "#5E745C",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: "#5E745C",
            fillOpacity: 0.0,
            zIndex: 1000 // higher than overlayStyle.zIndex
        },
        { // satellite
            strokeColor: "#F2B701",
            strokeOpacity: 1,
            strokeWeight: 1.5,
            fillColor: "#F2B701",
            fillOpacity: 0.0,
            zIndex: 1000 // higher than overlayStyle.zIndex
        }];

    PrimaryMouseOverStyle = { // use same style overrides for roadmap and satellite
        strokeWeight: 2,
        fillOpacity: 0.4
    };

    PrimarySelectedZoneStyles = [
        {  // roadmap
            strokeColor: "#404040",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#5E745C",
            fillOpacity: 0.9,
            zIndex: 100 // higher than overlayStyle.zIndex
        },
        { // satellite
            strokeColor: "#404040",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#F2B701",
            fillOpacity: 0.9,
            zIndex: 100 // higher than overlayStyle.zIndex
        }];

    SecondaryStyles = [
        { // roadmap
            strokeColor: "#B9BA92",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColors: ['#7F3C8D', '#11A579', '#3969AC', '#F2B701', '#E73F74', '#80BA5A', '#E68310', '#008695', '#CF1C90', '#f97b72', '#4b4b8f', '#A5AA99'],
            fillOpacity: 0.2,
            zIndex: 999
        },
        { // satellite
            strokeColor: "#fff",
            strokeOpacity: 0.75,
            strokeWeight: 1,
            fillColors: ['#7F3C8D', '#11A579', '#3969AC', '#F2B701', '#E73F74', '#80BA5A', '#E68310', '#008695', '#CF1C90', '#f97b72', '#4b4b8f', '#A5AA99'],
            fillOpacity: 0.2,
            zIndex: 999
        }];

    WindFarms = {
        id: "windfarms",
        type: "geojson",
        name: "Wind Farms",
        styler: function (zone, zoom, sat) {
            var style = this.styles[zone.props[1] - 1]; // property is 1..3
            style.icon = zoom < 11 ? style.icons[1] : style.icons[0];
            return style; 
        },
        styles: [
            { icons: [{ url: "/images/map/windapproved-v2.png" }, { url: "/images/map/windapproved-v2-s.png" }] },
            { icons: [{ url: "/images/map/windconstruction-v2.png" }, { url: "/images/map/windconstruction-v2-s.png" }] },
            { icons: [{ url: "/images/map/windoperating-v2.png"}, { url: "/images/map/windoperating-v2-s.png" }] }
        ],
        legend: [
            { name: "Approved" },
            { name: "Under construction" },
            { name: "Operating" }
        ],
        legendSource: 'Department of Environment, Land, Water and Planning, 2021',

        infoWindow: function (zone) {
            return ReactDOMServer.renderToStaticMarkup(getInfoWindowContent(zone));
        }
    };

    SolarFarms = {
        id: "solarfarms",
        type: "geojson",
        name: "Solar Farms",
        styler: function (zone, zoom, sat) {
            var style = this.styles[zone.props[1] - 1]; // property is 1..3
            style.icon = zoom < 11 ? style.icons[1] : style.icons[0];
            return style;
        },
        styles: [
            { icons: [{ url: "/images/map/solarfarmapproved-v2.png" }, { url: "/images/map/solarfarmapproved-v2-s.png" }] },
            { icons: [{ url: "/images/map/solarfarmconstruction-v2.png" }, { url: "/images/map/solarfarmconstruction-v2-s.png" }] },
            { icons: [{ url: "/images/map/solarfarmoperating-v2.png" }, { url: "/images/map/solarfarmoperating-v2-s.png" }] }
        ],
        legend: [
            { name: "Approved" },
            { name: "Under construction" },
            { name: "Operating" }
        ],
        legendSource: 'Department of Environment, Land, Water and Planning, 2021',

        infoWindow: function (zone) {
            return ReactDOMServer.renderToStaticMarkup(getInfoWindowContent(zone));
        }
    };

    BioEnergy = {
        id: "bioenergy",
        type: "geojson",
        name: "Bioenergy Plants",

        styler: function (zone, zoom, sat) {
            var style = this.style; // no variations yet
            style.icon = zoom < 11 ? style.icons[1] : style.icons[0];
            return this.style;
        },
        style: { icons: [{ url: "/images/map/bioenergy.png" }, { url: "/images/map/bioenergy-s.png" }] },
        legend: [{  }],
        legendSource: 'Geoscience Australia, 2016',

        infoWindow: function (zone) {
            return ReactDOMServer.renderToStaticMarkup(getInfoWindowContent(zone));
        }
    };

    TransLines = {
        id: "translines",
        type: "geojson",
        name: "Transmission Lines",
        style: {
            strokeColor: "#1414fe",
            strokeOpacity: 1,
            strokeWeight: 2
        },
        styler: function (zone, zoom, sat) {
            switch (zone.props[2]) { // property is kV values
                case "500": return this.styles[0];
                case "330": return this.styles[1];
                case "275": return this.styles[2];
                case "220": return this.styles[3];
                case "132": // shared with 110
                case "110": return this.styles[4];
                case "66:": return this.styles[5];
                default: return this.style;
            }
        },

        styles: [{
            strokeColor: "#FFED00",
            strokeOpacity: 1,
            strokeWeight: 2
        }, {
                strokeColor: "#F2AA2D",
                strokeOpacity: 1,
                strokeWeight: 2
            }, {
                strokeColor: "#E5007E",
                strokeOpacity: 1,
                strokeWeight: 2
            }, {
                strokeColor: "#0000FF",
                strokeOpacity: 1,
                strokeWeight: 2
            }, {
                strokeColor: "#E4022D",
                strokeOpacity: 1,
                strokeWeight: 2
            }, {
                strokeColor: "#774110",
                strokeOpacity: 1,
                strokeWeight: 2
            }
        ],
        legend: [{ name: "500 kV" }, { name: "330 kV" }, { name: "275 kV" }, { name: "220 kV" }, { name: "132 / 110 kV" }, { name: "66 kV" }],
        legendSource: 'Geoscience Australia, 2021'
    };

    BDoubleRoutes = {
        id: "bdouble",
        type: "geojson",
        name: "B Double Truck Routes",
        styler: function (zone) {
            let i = zone.props[0];
            return i > 0 ? this.styles[i - 1]: null; // property is 1..5
        },

        styles: [{
            strokeColor: "#202020",
            strokeOpacity: 1,
            strokeWeight: 5
        },
            {
                strokeColor: "#505050",
                strokeOpacity: 1,
                strokeWeight: 3
            },
            {
                strokeColor: "#808080",
                strokeOpacity: 1,
                strokeWeight: 1
            },
            {
                strokeColor: "#B08080",
                strokeOpacity: 1,
                strokeWeight: 1
            },
            {
                strokeColor: "#8080B0",
                strokeOpacity: 1,
                strokeWeight: 1
            }],
        legend: [{ name: "Freeway" }, { name: "Arterial Highway" }, { name: "Municipal Road" }, { name: "Other Arterial Road" }, { name: "Proposed Road" }],
        legendSource: 'Department of Transport, 2019'
    };

    WindFarmProhibited = {
        id: "windfarmsprohibited",
        type: "geojson",
        name: "Wind Farm Prohibited Areas",
        style: {
            strokeColor: "#008080",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: "#008080",
            fillOpacity: 0.3
        },
        legend: [{ name: "Prohibited Area" }],
        legendSource: 'Department of Environment, Land, Water & Planning, 2019'
    };

    SystemStrength = {
        id: "systemstrength",
        type: "geojson",
        name: "Substation Strength",
        styler: function (zone,zoom,sat) {
            return this.styles[zone.props[1]]; // property is 0..7
        },
        styles: [
            { icon: { url: "/images/map/substation0.png" } },
            { icon: { url: "/images/map/substation1.png" } },
            { icon: { url: "/images/map/substation2.png" } },
            { icon: { url: "/images/map/substation3.png" } },
            { icon: { url: "/images/map/substation4.png" } },
            { icon: { url: "/images/map/substation5.png" } },
            { icon: { url: "/images/map/substation6.png" } },
            { icon: { url: "/images/map/substation7.png" } }
        ],
        legend: [{ name: "Unknown" },{ name: "Low" }, {}, {}, { name: "Medium" }, {}, {}, { name: "High" }],
        legendSource: 'Australian Energy Market Operator, 2020'
    };

    DistToTransmissionLines = {
        id: "disttrans",
        name: "Distance To Transmission Lines",
        type: "geojson",
        style: {
            strokeColor: "#4DCA00",
            strokeWeight: 1,
            fillOpacity: 0.5
        },
        legend: [{
            name: "10 km intervals"
        }],
    };

    Wind150m = {
        id: "wind150m",
        name: "Wind Speed at 150m",
        type: "wms3857",
        url: "/proxy/wind150m",
        style: { fillOpacity: 0.75 },
        legend: [{
            // https://gis.aremi.data61.io/dnvgl/wms?version=1.1.1&request=GetLegendGraphic&layer=wind_150m&format=image/png&width=80&height=10
            image: "/images/map/wind150m-2.png"
        }],
        legendSource: 'Australian Bureau of Meteorology, 2021'
    };

    SolarExposure = {
        id: "solarexposure",
        name: "Solar Exposure",
        type: "wms3857",
        // does not support https so use our rewrite rules  
        url: "/proxy/solarexp",
        //url: "/http/neii.bom.gov.au/services/solarclim/wms/data/annClim_gloHorExp1Day.nc?service=WMS&version=1.1.1&request=GetMap&srs=EPSG%3A3857&transparent=true&format=image%2Fpng&layers=mean_exposure&styles=boxfill%2Frainbow&tiled=true&feature_count=101&colorscalerange=11%2C23.5",
        style: { fillOpacity: 0.75 },
        legend: [{
            // http://neii.bom.gov.au/services/solarclim/wms/data/annClim_gloHorExp1Day.nc?REQUEST=GetLegendGraphic&LAYER=mean_exposure&PALETTE=rainbow&srs=EPSG%3A3857&colorscalerange=11%2C23.5
            // /http/neii.bom.gov.au/services/solarclim/wms/data/annClim_gloHorExp1Day.nc?REQUEST=GetLegendGraphic&LAYER=mean_exposure&PALETTE=rainbow&srs=EPSG%3A3857&colorscalerange=11%2C23.5
            image: "/images/map/solarexposure-2.png"
        }],
        legendSource: 'Australian Bureau of Meteorology, 2021'
    };

    DwellingDensity = {
        id: "dwellingdensity",
        name: "Dwelling Density",
        type: "img",
        url: "/images/map/dwellingdensity.png",
        style: {},
        bounds: { north: -33.98042558699996, east: 149.979279289057, south: -39.1634743832868, west: 140.961676056716 },
        legend: [{ name: "0", style: { fillColor: "#FFFFFF", fillOpacity: 0 } },
            { name: "1-9", style: { fillColor: "#0000FF", fillOpacity: 1 } },
            { name: "10-99", style: { fillColor: "#008000", fillOpacity: 1 } },
            { name: "100-999", style: { fillColor: "#FFFF00", fillOpacity: 1 } },
            { name: "1000-9999", style: { fillColor: "#FFA500", fillOpacity: 1 } },
            { name: "10000+", style: { fillColor: "#FF0000", fillOpacity: 1 } }],
        legendFooter: "Dwellings per sq km"
    };

    Overlays = [this.WindFarms, this.SolarFarms, this.BioEnergy, this.TransLines,
        this.BDoubleRoutes, this.WindFarmProhibited, this.SystemStrength,
        this.DistToTransmissionLines, this.Wind150m,// this.SolarExposure,
        this.DwellingDensity];
}

