import React, { Component } from 'react';

import { GradientChart } from './GradientChart';
import { Config } from '../config/Config';

export class RenewableEnergyPotential extends Component {
    /* constructor(props) {
         super(props);
     }*/

    render() {
        if (this.props.region === null) return null;

        return (
            <div className="rep">
                <h3>Renewable Energy Potential</h3>
                <div className="container">
                    <div className="pane">
                        <h5>Capacity to deliver on State's renewable energy targets</h5>
                        <GradientChart className="light" markers={[this.props.region.repExistingIndex]} range={{ minLabel: "Lower", maxLabel: "Higher", min: Config.REPCapMin, max: Config.REPCapMax, minColor: "#5F7786", maxColor: "#232D39" }}/>
                        {this.props.region.repExistingCaption && 
                            this.props.region.repExistingCaption.map((x, i) => <p key={i}>{x || <React.Fragment>&nbsp;</React.Fragment>}</p>)
                        }
                    </div>
                    <div className="pane"><h5>Solar resources</h5>
                        <GradientChart className="light" markers={[this.props.region.repSolarIndex]} range={{ minLabel: "Lower", maxLabel: "Higher", min: Config.REPSolarMin, max: Config.REPSolarMax, minColor: "#B9BA92", maxColor: "#365549" }} />
                        <p>{this.props.region.repSolarCaption}</p>
                    </div>
                    <div className="pane">
                        <h5>Wind resources</h5>
                        <GradientChart markers={[this.props.region.repWindIndex]} range={{ minLabel: "Lower", maxLabel: "Higher", min: Config.REPWindMin, max: Config.REPWindMax, minColor: "#F0DAC1", maxColor: "#B67B71" }} />
                        <p>{this.props.region.repWindCaption}</p>
                    </div>
                    <div className="source">
                        <p>Indicators are based on data sourced from:</p>
                        <p>Department of Environment, Land, Water & Planning; Australian PV Institute; AEMO; and the Australian Renewable Energy Agency (AREMI).</p>
                    </div>
                </div>
            </div>
        );
    }
}