import React, { Component } from 'react';
import { Chart, BarController, LineController, LinearScale, CategoryScale, BarElement, LineElement, PointElement, Tooltip } from 'chart.js';
import { NumberFormatting } from '../helpers/NumberFormatting';
import { ChartHelper } from '../helpers/ChartHelper';

Chart.register(BarController, LineController, LinearScale, CategoryScale, BarElement, LineElement, PointElement, Tooltip);

export class PopulationTrendChart extends Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this._gradient = ChartHelper.MakeVertGradient(this.chartRef.current);

        // Store data locally to help the tooltip code
        this.data = this.getChartData();

        this._chart = new Chart(this.chartRef.current, {
            type: 'bar',
            options: {
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (context) => context[0].dataset.label,
                            label: (context) => context.datasetIndex === 0
                                ? context.label + ": " + NumberFormatting.FmtDigitGroups(context.parsed.y, 0)
                                : this.data.labels[context.dataIndex - 1] + " - " + context.label + ": " + NumberFormatting.FmtDigitGroups(context.parsed.y, 1) + "%"
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: "bottom",
                    reverse: true, // population first
                    // any option to make line legends appear as line with dot as drawn in chart ?
                },
                scales: {
                    y: {
                        id: 'pop',
                        position: 'left',
                        suggestedMin: 0,
                        ticks: {
                            callback: function (value, index, values) {
                                return NumberFormatting.FmtDigitGroups(value, 0);
                            }
                        }
                    },

                    yPct:
                        {
                            id: 'percent',
                            position: 'right',
                        suggestedMin: 0,
                            ticks: {
                                callback: function (value, index, values) {
                                    return NumberFormatting.FmtDigitGroups(value, 1) + "%";
                                }
                            },
                            grid: { display: false }
                        },
                    x: {
                        stacked: false,
                        grid: { display: false },
                        
                    }
                },
                maxBarThickness: 28,
            },
            data: this.data
        });
    }

    getChartData() {
        let data = this.props.region.erp.map((v, i) => ({
            year: this.props.region.erpFirstYear + i,
            pop: v,
            growth: i > 0 ? this.props.region.erpGrowth[i - 1] * 100 : null
        }));

        return {
            labels: data.map(v => v.year),
            datasets: [{
                label: 'People',
                data: data.map(v => v.pop),
                yAxisID: 'y',
                backgroundColor: this._gradient,
                order: 2
            }, {
                label: "Growth Rate",
                data: data.map(v => v.growth),
                yAxisID: 'yPct',
                type: "line",
                fill: false,
                backgroundColor: '#364955',
                borderColor: '#364955',
                pointRadius: 6,
                order: 1,
                lineTension: 0.4
            }]
        };
    }

    updateChart() {
        if (this._chart) {
            this._chart.data = this.getChartData();
            this._chart.update();
        }
    }

    render() {
        let height = "15em";
        let style = { minHeight: height, height: height };

        this.updateChart();

        return <div className="populationtrendchart">
            <h5>Population Trends</h5>
            <div className="jschartContainer" style={style}>
                <canvas ref={this.chartRef} />
            </div>
        </div>

    }
}

/* chart js expected data
{
    labels: ['A', 'B', 'C', ...],
        datasets: [{
            label: 'My data',
            data: [10, 20, 30, ...],
            backgroundColor: '#112233'
        }]
}
*/