export class NumberFormatting {

    // toLocaleString may not support option param on older browsers..need to detect and code around!
    static FmtSafePercent(value, denom, decPlaces) {
        if (denom === 0) return "n/a";

        return (value / denom).toLocaleString(undefined, { style: "percent", maximumFractionDigits: decPlaces });
    }

    /**
     * @param {any} value 0..1
     * @param {any} decPlaces 0+ decimal places
     * @returns {string} formatted string without the trailing %
     */
    static FmtPercent(value, decPlaces) {
        return (value).toLocaleString(undefined, { style: "percent", maximumFractionDigits: decPlaces });
    }

    static FmtDigitGroups(value, decPlaces) {
        return (value).toLocaleString(undefined, { style: "decimal", maximumFractionDigits: decPlaces });
    }
}
