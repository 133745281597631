import React, { Component } from 'react';
import { Chart, LineController, LinearScale, CategoryScale, BarElement, LineElement, PointElement, Tooltip } from 'chart.js';
import { NumberFormatting } from '../helpers/NumberFormatting';

Chart.register(LineController, LinearScale, CategoryScale, BarElement, LineElement, PointElement, Tooltip);

export class UnemploymentRateChart extends Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this._chart = new Chart(this.chartRef.current, {
            type: 'bar',
            options: {
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (context) => context[0].dataset.label,
                            label: (context) => context.label + ": " + NumberFormatting.FmtDigitGroups(context.parsed.y, 1) + "%"
                        }
                    },
                },
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: "bottom",
                    // any option to make line legends appear as line with dot as drawn in chart ?
                },
                scales: {
                    y: {
                        suggestedMin: 0,
                        ticks: {
                            callback: function (value, index, values) {
                                return NumberFormatting.FmtDigitGroups(value,0) + "%";
                            }
                        }
                    },
                    x: {
                        stacked: false,
                        grid: { display: false }
                    }
                }
            },
            data: this.getChartData()
        });
    }

    getChartData() {
        let data = this.props.region.unemploymentRate.map((v, i) => ({
            region: v * 100,
            state: this.props.region.unemploymentRateState[i] * 100,
            year: this.props.region.unemploymentFirstYear + i
        }));

        return {
            labels: data.map(v => v.year),
            datasets: [{
                label: 'Region',
                type: 'line',
                fill: false,
                backgroundColor: '#A3A572',
                borderColor: '#A3A572',
                pointRadius: 6,
                data: data.map(v => v.region),
                order: 1,
                lineTension: 0.4
            },
            {
                label: 'State',
                type: 'line',
                fill: false,
                backgroundColor: '#364955',
                borderColor: '#364955',
                pointRadius: 6,
                data: data.map(v => v.state),
                order: 2,
                lineTension: 0.4
            }]
        };
    }

    updateChart() {
        if (this._chart) {
            this._chart.data = this.getChartData();
            this._chart.update();
        }
    }

    render() {
        let height = "15em";
        let style = { minHeight: height, height: height };

        this.updateChart();

        return <div className="unemploymentratechart">
            <h5>Unemployment Rate</h5>
            <div className="jschartContainer" style={style}>
                <canvas ref={this.chartRef} />
            </div>
        </div>

    }
}

/* chart js expected data
{
    labels: ['A', 'B', 'C', ...],
        datasets: [{
            label: 'My data',
            data: [10, 20, 30, ...],
            backgroundColor: '#112233'
        }]
}
*/