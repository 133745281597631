import React, { Component } from 'react';
import { MapControlOnOff } from './MapControlOnOff';
import { MapControlsExpand } from './MapControlsExpand';

import { Services } from '../services/Services';

export class MapOverlayLayers extends Component {
    constructor(props) {
        super(props);

        this.state = { expanded: true };
        this._overlayLibrary = Services.getOverlayLibrary();

        this.onExpand = this.onExpand.bind(this);
    }

    onExpand(e) {
        this.setState({ expanded: !this.state.expanded });
}

    render() {
        return (
            <div className="maplayers">
                <MapControlsExpand checked={this.state.expanded} onClick={this.onExpand} />
                <h3>3: Map Layer Controls</h3>
                <div className={"pane" + (this.state.expanded ? " expanded" : " collapsed")}>
                    {this._overlayLibrary.Overlays.map( i => 
                        <MapControlOnOff key={i.id} title={i.name} isChecked={this.props.overlayLayers[i.id]} tag={i.id} onClick={this.props.onOverlayLayer} />
                        )}
                </div>
            </div>
        );
    }
}