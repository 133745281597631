import React, { Component } from 'react';

import { NumberFormatting } from '../helpers/NumberFormatting';

export class EconomicImpactVicMap extends Component {
    render() {
        return (<div className="resultmap">
            <img alt="Victoria" src="/images/victoria.svg" />
            <h5>{NumberFormatting.FmtDigitGroups(this.props.constructJobs, 0)} jobs</h5><p>construction phase</p><p>Victoria</p><h5>{NumberFormatting.FmtDigitGroups(this.props.operJobs, 0)} jobs</h5><p>operational phase</p><p>Victoria</p>
        </div>);
    }
}