export class LoggerService {
    debug(s) {
        console.log(s);
    }

    warn(s) {
        console.log("WARN: " + s);
    }

    error(s) {
        console.log("ERROR: " + s);
    }
}