// consider using https://github.com/JedWatson/classnames

import React, { Component } from 'react';

export class MapControlOnOff extends Component {

/*  constructor(props) {
      super(props);
  }*/

    render() {

    return (
        <div className={"maponoff" + (!this.props.isDisabled && this.props.isChecked ? " checked" : "") + (this.props.isDisabled ? " disabled" : "")} onClick={(e) => this.props.onClick(this.props.tag)}><div className="box"><div className="knob"/></div>{this.props.title}</div>
    );
  }
}
