import React, { Component } from 'react';
import queryString from 'query-string';

import { MapControls } from './MapControls';
import { MapControlsMinimize } from './MapControlsMinimize';
import { Legends } from './Legends';
import { Map } from './Map';
import { RegionInsights } from './RegionInsights';
import { Banner } from './Banner';
import { Header } from './Header';
import { HeaderTiles } from './HeaderTiles';
import { About } from './About';
import { Footer } from './Footer';

import { ParsingHelper } from '../helpers/ParsingHelper';

import { RegionType } from '../constants/RegionType';

import { APIService } from '../services/APIService';
import { Services } from '../services/Services';

export class MainApp extends Component {
    // eslint-disable-next-line no-use-before-define
    displayName = MainApp.name

    constructor(props) {
        super(props);

        // use service container?
        this._api = Services.getAPI();
        this._mapService = Services.getMap();
        this._logger = Services.getLogger();

        //let params = useParams();
        let type = ParsingHelper.mapType(props.routeParams?.type);
        //const query = queryString.parse(props.location?.search);

        let mw = ParsingHelper.parseMW(props.queryParams?.get("mw"));
        if (mw === undefined) mw = 10;

        this.state = {
            type: type,
            regionNames: [],
            overlayType: RegionType.None,
            overlayLayerIds: {},
            regionType: type,
            name: this.props.routeParams?.name,
            zoneId: null,
            region: null,
            insight: ParsingHelper.mapInsight(this.props.routeParams?.insight),
            mapControlsMinimized: false,
            impact: { mw: mw, mwvalue: mw, type: ParsingHelper.mapImpactType(props.queryParams?.get("type"))}
        };

        // All callbacks need this js treatment
        this.onType = this.onType.bind(this);
        this.onOverlayType = this.onOverlayType.bind(this);
        this.onOverlayLayer = this.onOverlayLayer.bind(this);
        this.onInsight = this.onInsight.bind(this);
        this.onRegionName = this.onRegionName.bind(this);
        this.onZoneId = this.onZoneId.bind(this);
        this.onRegion = this.onRegion.bind(this);
        //this.onNames = this.onNames.bind(this);
        this.onMapControlsMinimized = this.onMapControlsMinimized.bind(this);
        this.onLegendClick = this.onLegendClick.bind(this);
        this.onImpact = this.onImpact.bind(this);
        this.buildUrl = this.buildUrl.bind(this);

        this._mapService.onZoneId = this.onZoneId;
    }

    componentDidMount() {
       // this.fetchNames(this.state.type);

        // If route had a type and name
        if (this.state.name) {
            this._api.getRegionByName(this.state.regionType, this.state.name, this.onRegion);
        }

        //this._mapService.setState({ type: this.state.type });
    }

    componentWillUnmount() {
    }

    onType(id) {
        if (id === this.state.type) return;

        // If type is same as overlay then disable the overlay
        let overlayType = this.state.overlayType === id ? 0 : this.state.overlayType;

        let newState = { type: id, overlayType: overlayType, region: null, regionType: null, name: null, regionLoading: false, zoneId: null };
        this.setState(newState, () => this.buildUrl());

      //  this.fetchNames(id);
    }

    onOverlayType(id) {
        id = this.state.overlayType === id ? RegionType.None : id;
        this.setState({ overlayType: id });
    }

    onOverlayLayer(id) {
        // always mutate a copy!
        let overlayLayerIds = Object.assign({}, this.state.overlayLayerIds);

        overlayLayerIds[id] = !overlayLayerIds[id];
        this.setState({ overlayLayerIds: overlayLayerIds });
    }

    onRegionName(name) {
        this.setState({ regionLoading: true, name: name, zoneId: null });
        this._api.getRegionByName(this.state.type, name, this.onRegion);
    }

    onZoneId(zoneId) {
        // If zone is currently selected zone then turn it all off
        if (zoneId === this.state.zoneId) {
            this.setState({ regionLoading: false, zoneId: null, region: null, regionType: null, name: null }, () => this.buildUrl());
        }
        else {
            this.setState({ regionLoading: true, zoneId: zoneId, name: null }, () => this.buildUrl());
            this._api.getRegionByZoneId(zoneId, this.onRegion);
        }
    }

    onInsight(id) {
        this.setState({ insight: id }, () => this.buildUrl());
    }

    onRegion(region, e) {
        // If region matches what we last requested
        if (region && ((region.zoneId === this.state.zoneId) || (region.name === this.state.name))) {
            let regionType = APIService.convertIndexToType(region.regionType);
            this.setState({ region: region, regionType: regionType, regionName: region.name, regionLoading: false, zoneId: region.zoneId }, () => this.buildUrl());
        }
        else
        {
            this.setState({ region: null, regionType: null, regionLoading: false }, () => this.buildUrl());
        }
    }

    onImpact(impact) {
        this.setState({ impact: impact }, () => this.buildUrl());
    }

    buildUrl() {
        let url = "/";
        if (this.state.regionType) {
            url = url + this.state.regionType + "/" + this.state.regionName + "/" + this.state.insight;
        }

        url = url + "?mw=" + this.state.impact.mwvalue + "&type=" + this.state.impact.type;
        this.props.navigate(url);
    }
/*
    fetchNames(type) {
        this.setState({ regionNames: [] });
        this._api.getRegionNames(type, this.onNames);
    }

    onNames(names, e) {
        if (names) this.setState({ regionNames: names });
    }
*/

    onMapControlsMinimized() {
        this.setState({ mapControlsMinimized: !this.state.mapControlsMinimized });
    }

    hasSelectedOverlays() {
        return Object.keys(this.state.overlayLayerIds).some(x => this.state.overlayLayerIds[x]);
    }

    onLegendClick() {
        if (this.hasSelectedOverlays() && !this.state.mapControlsMinimized) {
            this.onMapControlsMinimized();
        }
    }

    render() {
        this._mapService.setState({ type: this.state.type, overlayType: this.state.overlayType, overlayLayerIds: this.state.overlayLayerIds, zoneId: this.state.zoneId });

        return (<div className="mainapp">
            <Header />
            <Banner />
            <HeaderTiles/>
            <About />
            <div className={"map-container" + (this.state.mapControlsMinimized ? " map-controls-minimized" : "") + (this.hasSelectedOverlays() ? " legend-enabled" : " legend-disabled")}>
                <MapControls type={this.state.type} overlayType={this.state.overlayType} overlayLayers={this.state.overlayLayerIds} onType={this.onType} onOverlayType={this.onOverlayType} onOverlayLayer={this.onOverlayLayer} />
                <Map name={this.state.name} type={this.state.type} names={this.state.regionNames} onZoneId={this.onZoneId} mapService={this._mapService} />
                <Legends overlayLayerIds={this.state.overlayLayerIds} library={Services.getOverlayLibrary()} onLegendClick={this.onLegendClick} />
                <MapControlsMinimize checked={this.state.mapControlsMinimized} onClick={this.onMapControlsMinimized} />
                <div className="legend-title" onClick={this.onMapControlsMinimized}>Legend</div>
            </div>
            <RegionInsights type={this.state.type} name={this.state.name} region={this.state.region} insight={this.state.insight} onInsight={this.onInsight} onImpact={this.onImpact} impact={this.state.impact} />
            <Footer />
        </div>);
    }
}
