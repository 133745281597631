import React, { Component } from 'react';
import { NumberFormatting } from '../helpers/NumberFormatting';
import { RegionInsightInfoGraphic } from './RegionInsightInfoGraphic';

export class MiscellaneousDemogs extends Component {

    render() {
        return <div className="miscdemogs">
            <RegionInsightInfoGraphic title="Labour Force Participation Rate" value={NumberFormatting.FmtPercent(this.props.region.labourForceRate)} icon="/images/icons/guage.svg" />
            <RegionInsightInfoGraphic title="Youth Engagement" value={NumberFormatting.FmtPercent(this.props.region.youthEngagement)} icon="/images/icons/guage.svg" />
        </div>;
    }
}
