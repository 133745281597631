export class ImageLayer {
    map = null;

    constructor(url, bounds) {
        this.groundOverlay = new window.google.maps.GroundOverlay(url, bounds);
    }

    setMap(map) {
        if (map !== this.map) {
            this.map = map;
            this.groundOverlay.setMap(this.map);
        }
    }
}