import React, { Component } from 'react';

import { ImpactType } from '../constants/ImpactType';
import { NumberFormatting } from '../helpers/NumberFormatting';

export class EconomicImpactInputs extends Component {

    getMaxApproved(type) {
        // Some limits
        switch (type) {
            case ImpactType.Biomass: return 1;
            case ImpactType.SolarFarm: return 430;
            case ImpactType.Wind: return 800;
            default: return undefined;
        }
    }

    render() {
        let maxApproved = this.getMaxApproved(this.props.type);

        return (<div className="inputs">
            <h5>MW Generation</h5>
            <input className="mw" type="text" value={this.props.mw} onChange={e => this.props.onMW(e.target.value)} />
            <h5>Generation Type</h5>
            <label className="type"><input type="radio" name="type" value={ImpactType.Wind} onChange={(e) => this.props.onType(e.target.value)} checked={this.props.type === ImpactType.Wind} />Wind</label>
            <label className="type"><input type="radio" name="type" value={ImpactType.SolarFarm} onChange={(e) => this.props.onType(e.target.value)} checked={this.props.type === ImpactType.SolarFarm} />Solar Farm</label>
            <label className="type"><input type="radio" name="type" value={ImpactType.SolarRooftop} onChange={(e) => this.props.onType(e.target.value)} checked={this.props.type === ImpactType.SolarRooftop} />Solar Roof Top</label>
            <label className="type"><input type="radio" name="type" value={ImpactType.Biomass} onChange={(e) => this.props.onType(e.target.value)} checked={this.props.type === ImpactType.Biomass} />Biomass</label>

            {maxApproved && <div className="notes">
                <p>Note:</p>
                <p>The largest approved {this.props.fmtType} in Victoria has a capacity of {NumberFormatting.FmtDigitGroups(maxApproved, 0)} MW.</p>
            </div>}
        </div>);
    }
}
