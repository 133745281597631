import React, { Component } from 'react';

import { GradientChart } from './GradientChart';
import { Config } from '../config/Config';

export class Infrastructure extends Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        if (this.props.region === null) return null;

        return (
            <div className="infrastructure">
                <h3>Infrastructure</h3>
                <div className="container">
                    <div className="pane">
                        <h5>Proximity to transmission</h5>
                        <GradientChart className="light" markers={[this.props.region.distIndex]} range={{ minLabel: "Lower", maxLabel: "Higher", min: Config.DistToTransMin, max: Config.DistToTransMax, minColor: "#5F7786", maxColor: "#232D39" }} />
                        <p>{this.props.region.distCaption}</p>
                    </div>
                    <div className="pane">
                        <h5>Size of transmission</h5>
                        <GradientChart className="light" markers={[this.props.region.transIndex]} range={{ minLabel: "Lower", maxLabel: "Higher", min: Config.TransLineSizeMin, max: Config.TransLineSizeMax, minColor: "#B9BA92", maxColor: "#365549" }} />
                        <p>{this.props.region.transCaption}</p>
                    </div>
                    <div className="pane">
                        <h5>Network capacity</h5>
                        <GradientChart markers={[this.props.region.substationIndex]} range={{ minLabel: "Lower", maxLabel: "Higher", min: Config.SystemStrengthMin, max: Config.SystemStrengthMax, minColor: "#F0DAC1", maxColor: "#B67B71" }} />
                        <p>{this.props.region.substationCaption}</p>
                    </div>
                    <div className="pane">
                        <h5>Road Access</h5>
                        <GradientChart className="light" markers={[this.props.region.bDoubleIndex]} range={{ minLabel: "Lower", maxLabel: "Higher", min: Config.BDoubleRouteMin, max: Config.BDoubleRouteMax, minColor: "#94ABB9", maxColor: "#365549" }} />
                        <p>{this.props.region.bDoubleCaption}</p>
                    </div>
                    <div className="source">
                        <p>Indicators are based on data sourced from:</p>
                        <p>Geoscience Australia; Australian Energy Market Operator; and the Department of Transport.</p>
                    </div>
                </div>
            </div>
        );
    }
}