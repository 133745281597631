import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="container">
                    <h5>Project Partners</h5>
                    <div className="links"><a href="https://www.energy.vic.gov.au/renewable-energy/victorias-renewable-energy-roadmap" rel="noopener noreferrer" target="_blank"><img alt="victoria" src="/images/logos/vic2.png" /></a></div>
                    <div className="links2"><Link to="/disclaimer">Disclaimer</Link></div>
                </div>
            </div>
        );
    }
}