import { RegionType } from '../constants/RegionType';
import { Config } from '../config/Config';
import { WMSTileLayer, WMS3857TileLayer } from './WMSTileLayer';
import { GeoJsonLayer } from './GeoJsonLayer';
import { ImageLayer } from './ImageLayer';

export class MapService {
    map = null;
    onZoneId = null;
    _primaryGeogId = null;
    _primaryLayer = null;
    _secondaryGeogId = null;
    _secondaryLayer = null;
    _overlayLayerIds = {};
    _overlayLayers = [];
    _zoneId = null;
    _infoWindow = null;
    _infoWindowLayerId = null;
    _tooltip = null;
    _projOverlay = null;
    _mapEl = null;
    _mapTypeStyleIndex = 0;
    _mapZoom = 0;
    /** tracks previous tooltip hover region to restore it on same region multi layer mouse out */
    _lastZoneName = null;

    constructor(api, cache, library, logger) {
        this._api = api;
        this._cache = cache;
        this._logger = logger;
        this._library = library;

        this.mouseover = this.mouseover.bind(this);
        this.mouseout = this.mouseout.bind(this);
        this.mousemove = this.mousemove.bind(this);
        this.click = this.click.bind(this);
        this.onStyle = this.onStyle.bind(this);
        this.mapTypeChanged = this.mapTypeChanged.bind(this);
        this.mapZoomChanged = this.mapZoomChanged.bind(this);
    }

    getMapStyles() {
        return window.JSON.parse(Config.GoogleMapsJSStyle);
    }

    setMap(map) {
        if (map === this.map) return;

        if (this.map) {
            this.map.data.setStyle(null);
            this.map.data.removeListener(this._idClick);
            this.map.data.removeListener(this._idMouseOut);
            this.map.data.removeListener(this._idMouseOver);
            this.map.data.removeListener(this._idMouseMove);
            this.map.data.removeListener(this._mapTypeChanged);
            this.map.data.removeListener(this._mapZoomChanged);
        }

        this.map = map;

        if (this.map) {
            // the anchors use the google.maps namespace which is not valid at startup so we defer that to here
            this._library.initIconAnchors();

            this._idMouseOver = this.map.data.addListener('mouseover', this.mouseover);
            this._idMouseOut = this.map.data.addListener('mouseout', this.mouseout);
            this._idMouseMove = this.map.data.addListener('mousemove', this.mousemove);
            this._idClick = this.map.data.addListener('click', this.click);
            this._mapTypeChange = this.map.addListener('maptypeid_changed', this.mapTypeChanged);
            this._mapZoomChange = this.map.addListener('zoom_changed', this.mapZoomChanged);

            // catchup on any pending calls
            this.setBounds(Config.MapBounds);
            this.updateMapLayers();

            this._infoWindow = new window.google.maps.InfoWindow();
            this._projOverlay = new window.google.maps.OverlayView();
            this._projOverlay.draw = () => {};
            this._projOverlay.setMap(map);
            this._mapEl = document.querySelectorAll('.map-container .map')[0];
        }
    }

    mapTypeChanged() {
        this.updateMapLayers();
    }

    mapZoomChanged() {
        this._mapZoom = this.map.getZoom();

        this.updateMapLayers();
    }

    setBounds(bounds) {
        this.map.fitBounds(new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bounds.s, bounds.w), new window.google.maps.LatLng(bounds.n, bounds.e)));
    }

    setState(state) {
        let primaryGeogId = this.mapTypeToGeogId(state.type);
        let secondaryGeogId = this.mapTypeToGeogId(state.overlayType);

        if ((primaryGeogId !== this._primaryGeogId) || (secondaryGeogId !== this._secondaryGeogId) || (state.zoneId !== this._zoneId) || (state.overlayLayerIds !== this._overlayLayerIds))
        {
            this._overlayLayerIds = state.overlayLayerIds;
            this._primaryGeogId = primaryGeogId;
            this._secondaryGeogId = secondaryGeogId;
            this._zoneId = state.zoneId;
            this.updateMapLayers();
        }
    }

    // map.data events

    onStyle(feature) {
        let zone = feature.getProperty("zone");

        return zone.layer.styler
            ? zone.layer.styler(zone, this._mapZoom, this._mapTypeStyleIndex === 1)
            : zone.layer.style;
    }

    onStylePrimary(zone) {
        if (zone.props[0] === this._zoneId)
            return this._library.PrimarySelectedZoneStyles[this._mapTypeStyleIndex];

        return this._library.PrimaryStyles[this._mapTypeStyleIndex];
    }

    onStyleSecondary(zone) {
        let zoneIdx = zone.layer.zones.findIndex(x => x === zone);
        let style = this._library.SecondaryStyles[this._mapTypeStyleIndex];
        style.fillColor = style.fillColors[zoneIdx % style.fillColors.length];
        return style;
    }

    mouseover(e) {
        let zone = e.feature.getProperty("zone");
        //zone.layer.mouseover(zone);
        if (zone.layer.index === 1) // 1=primary
            this.map.data.overrideStyle(e.feature, this._library.PrimaryMouseOverStyle); // different style 

        // Get name
        const nameIdx = zone.layer.props.findIndex(p => p.toLowerCase() === 'name');
        if (zone.props[nameIdx]) {
            if (zone.layer.index >= 1) { // 1=primary, 2=secondary 0=other
                this._lastZoneName = zone.props[nameIdx];
            }

            this.setTooltip(e, zone.props[nameIdx]);
        }
    }

    mouseout(e) {
        let zone = e.feature.getProperty("zone");
        //zone.layer.mouseout(zone);
        if (zone.layer.index === 1) { // 1=primary
            this.map.data.revertStyle(e.feature);
            this.removeTooltip(e);
        }
        else if (!this._lastZoneName) {
            this.removeTooltip(e);
        }
        else {
            this.setTooltip(e, this._lastZoneName);
        }
    }

    mousemove(e) {
        this.moveTooltip(e);
    }

    click(e) {
        let zone = e.feature.getProperty("zone");
        if (zone.layer.index === 1) // 1=primary
            this.onZoneId(zone.props[0]);

        this.setInfoWindow(zone, e.latLng.toJSON() /* creates object, not json */);
    }

    setInfoWindow(zone, latLng) {
        let content = zone.layer.infoWindow ? zone.layer.infoWindow(zone) : null;

        if (content) {
            this._infoWindow.setContent(content);
            this._infoWindow.setOptions({pixelOffset: new window.google.maps.Size(0, -20)});
            this._infoWindow.setPosition(latLng);
            this._infoWindow.open(this.map);
            this._infoWindowLayerId = zone.layer.geogId;
        }
        else {
            this._infoWindow.close();
            this._infoWindowLayerId = null;
        }
    }

    mapTypeToGeogId(type) {
        switch (type) {
            case RegionType.LGA: return "vic2019lga";
            case RegionType.REZ: return "vic2020rez";
            case RegionType.StateRegion: return "vic2019sgr";
            // and more here
            default:
                return null;
        }
    }

    // geojson layer

    updateMapLayers(forceLayers) {
        if (!this.map) return;

        // Some styles depend on the current map type
        var mapType = this.map.getMapTypeId();
        this._mapTypeStyleIndex = (mapType === 'hybrid' || mapType === 'satellite') ? 1 : 0;

        this._mapZoom = this.map.getZoom();

        // Turn off info window if its layer was hidden
        if (this._infoWindowLayerId && !this._overlayLayerIds[this._infoWindowLayerId]) {
            this._infoWindow.close();
            this._infoWindowLayerId = null;
        }

        // Remove layers first 
        // Handles case where secondary layer becomes the primary layer, and we end up
        // - adding it as primary
        // - and then removing it as secondary
        if (this._primaryLayer && this._primaryLayer.geogId !== this._primaryGeogId) {
            this._primaryLayer.setMap(null);
            this._primaryLayer.index = 0;
            this._primaryLayer = null;
        }

        if (this._secondaryLayer && this._secondaryLayer.geogId !== this._secondaryGeogId) {
            this._secondaryLayer.setMap(null);
            this._secondaryLayer.index = 0;
            this._secondaryLayer = null;
        }

        // Add the primary layer
        if (!this._primaryLayer) {
            this._primaryLayer = this.fetchLayer(this._primaryGeogId, this.updateMapLayers.bind(this));
            if (this._primaryLayer) {
                this._primaryLayer.styler = this.onStylePrimary.bind(this);
                this._primaryLayer.index = 1; // 1=primary
                this._primaryLayer.setMap(this.map);
            }
        }

        // Add the secondary layer
        if (!this._secondaryLayer) {
            this._secondaryLayer = this.fetchLayer(this._secondaryGeogId, this.updateMapLayers.bind(this));
            if (this._secondaryLayer) {
                this._secondaryLayer.styler = this.onStyleSecondary.bind(this);
                this._secondaryLayer.index = 2; // 2=secondary
                this._secondaryLayer.setMap(this.map);
            }
        }

        // Handle the overlays
        for (var i = 0; i < this._library.Overlays.length; i++) {
            let item = this._library.Overlays[i];
            let show = !!this._overlayLayerIds[item.id];
            if (!show && this._overlayLayers[i]) {
                this._overlayLayers[i].setMap(null);
            }

            if (show) {
                if (!this._overlayLayers[i]) {
                    this._overlayLayers[i] = this.makeLayer(item);
                }
                if (this._overlayLayers[i]) {
                    this._overlayLayers[i].setMap(this.map);
                }
            }
        }

        // Force styling update
        this.map.data.setStyle(this.onStyle);
    }

    makeLayer(libitem) {
        if (libitem.type === "img")
            return new ImageLayer(libitem.url, libitem.bounds);

        if (libitem.type === "wms3857")
            return new WMS3857TileLayer(libitem.url, libitem.style.fillOpacity);

        if (libitem.type === "wms")
            return new WMSTileLayer(libitem.url, libitem.style.fillOpacity);

        if (libitem.type === "geojson") {
            let layer = this.fetchLayer(libitem.id);
            if (layer) {
                layer.styler = libitem.styler ? libitem.styler.bind(libitem) : null;
                layer.style = libitem.style;
                layer.infoWindow = libitem.infoWindow ? libitem.infoWindow.bind(libitem) : null;
            }
            return layer;
        }
    }

    fetchLayer(geogId) {
        if (!this.map) return null;

        let layer = this._cache.get(geogId);
        if (layer) return layer;

        let data = this._api.getGeog(geogId, function (d, e) {
            if (d) {
                this.updateMapLayers();
            }
        }.bind(this));

        if (data) {
            layer = new GeoJsonLayer(data, this._logger);
            this._cache.put(geogId, layer);

            return layer;
        }

        return null;
    }

    //#region -- Tooltips --

    buildTooltip() {
        this._tooltip = document.createElement('div');
        this._tooltip.className = 'map-tooltip';
        
        document.body.appendChild(this._tooltip);
    }

    setTooltip(e, str) {
        if (!this._tooltip) {
            this.buildTooltip();
            this.moveTooltip(e);
        }

        this._tooltip.innerHTML = str;
    }

    moveTooltip(e) {
        if (this._tooltip) {
            const projection = this._projOverlay.getProjection();
            
            if (projection) {
                const client = projection.fromLatLngToContainerPixel(e.latLng);
                const mapPos = (this._mapEl['getBoundingClientRect'] && this._mapEl['getBoundingClientRect']()) || { left: 0, top: 0, width: 0 };

                // Offsets +/- 20 to pad tooltip from cursor
                this._tooltip.style.top = mapPos.top + client.y - 20 + 'px';

                if (client.x + 150 > mapPos.width) {
                    this._tooltip.style.left = "";
                    this._tooltip.style.right = mapPos.width - client.x + 20 + 'px'; 
                }
                else {
                    this._tooltip.style.left = mapPos.left + client.x + 20 + 'px';
                    this._tooltip.style.right = "";
                }
            }
        }
    }

    removeTooltip() {
        if (this._tooltip) {
            document.body.removeChild(this._tooltip);
            this._tooltip = null;
            this._lastZoneName = null;
        }
    }

    //#endregion -- Tooltip --
}
