import { CacheService } from './CacheService';
import { APIService } from './APIService';
import { MapService } from './MapService';
import { LoggerService } from './LoggerService';
import { MapOverlayLibrary } from './MapOverlayLibrary';

// IOC container
export class Services {
    static _cache = null;
    static _api = null;
    static _logger = null;
    static _overlayLibrary = null;

    static getLogger() {
        if (!this._logger) this._logger = new LoggerService();
        return this._logger;
    }

    static getCache() {
        if (!this._cache) this._cache = new CacheService(this.getLogger());
        return this._cache;
    }

    static getAPI() {
        if (!this._api) this._api = new APIService(this.getCache(), this.getLogger());
        return this._api;
    }

    // transient
    static getMap() {
        return new MapService(this.getAPI(), this.getCache(), this.getOverlayLibrary(), this.getLogger());
    }

    static getOverlayLibrary() {
        if (!this._overlayLibrary) this._overlayLibrary = new MapOverlayLibrary();
        return this._overlayLibrary;
    }
}