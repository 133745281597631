import React, { Component } from 'react';

export class Banner extends Component {
    constructor(props) {
        super(props);

        this.shareLink = this.shareLink.bind(this);
        this.closeSharePopup = this.closeSharePopup.bind(this);
        this.shareInputFocus = this.shareInputFocus.bind(this);

        this.state = {
            linkUrl: '',
            showLinkPopup: false
        };
    }

    shareFacebook() {
        const url = encodeURIComponent(window.location.href);
        window.open('https://www.facebook.com/sharer.php?u=' + url, "pop", "width=600, height=400, scrollbars=yes");
    }

    shareTwitter() {
        const url = encodeURIComponent(window.location.href);
        window.open('https://twitter.com/intent/tweet?url=' + url, "twitter", "width=600, height=400, scrollbars=yes");
    }

    shareLinkedIn() {
        const url = encodeURIComponent(window.location.href);
        window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + url, "linkedin", "width=600, height=400, scrollbars=yes");
    }

    shareLink() {
        const url = window.location.href;
        this.setState({ linkUrl: url, showLinkPopup: true });
        window.setTimeout(() => this.refs.shareInput.focus());
    }

    closeSharePopup() {
        this.setState({ showLinkPopup: false });
    }

    shareInputFocus() {
        this.refs.shareInput.select();
    }

    render() {
        var popupClass = 'share-popup' + (this.state.showLinkPopup ? ' active' : '');
        return (
            <div className="banner">
                <div className="container">
                    <a target="link" href="https://www.remplan.com.au/"><img alt="remplan" src="/images/logos/remplan2.png" /></a>                    
                    <div className="share">
                        Share:
                        <a href="#" onClick={this.shareFacebook}><img src="/images/share/facebook-square.svg" alt="Facebook" /></a>
                        <a href="#" onClick={this.shareTwitter}><img src="/images/share/twitter-square.svg" alt="Twitter" /></a>
                        <a href="#" onClick={this.shareLinkedIn}><img src="/images/share/linkedin.svg" alt="LinkedIn" /></a>
                        <div className="share-link">
                            <a href="#" onClick={this.shareLink}><img src="/images/share/link.svg" alt="Link" /></a>
                            <div className={popupClass}>
                                <div className="arrow" />
                                <h4>Copy the link below to share this page.</h4>
                                <p><input ref="shareInput" type="text" readOnly value={this.state.linkUrl} onFocus={this.shareInputFocus} /></p>
                                <div><button type="button" onClick={this.closeSharePopup}>Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}