const key = '__USR';

export class TempHelper {
    static va_l1d = (u,p) => {
        return u === atob('cHJldmlldw==') && p === atob('TGQjKGN4MmRmXw==');
    }

    static addToStore(u) {
        if (u) {
            const expiry = new Date().getTime() + (15 /* minutes */ * 60000);
            u.expires = new Date(expiry);

            window.localStorage.setItem(key, btoa(JSON.stringify(u)));
        }
        else {
            window.localStorage.removeItem(key);
        }
    }

    static getFromStore() {
        const usr = JSON.parse(atob(window.localStorage.getItem(key) || '') || null);

        if (usr && usr.expires) {
            const now = new Date().getTime() / 1000;

            usr.expires = new Date(usr.expires).getTime() / 1000;

            if (usr.expires > now) {
                return {
                    user: usr,
                    error: null
                };
            }
            else {
                return {
                    user: null,
                    error: 'Session expired'
                };
            }
        }

        return {user: usr, error: null};
    }
}