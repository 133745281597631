import React, { Component } from 'react';
import { MapZones } from './MapZones';
import { MapOverlayZones } from './MapOverlayZones';
import { MapOverlayLayers } from './MapOverlayLayers';

export class MapControls extends Component {
    render() {
        return (
            <div className={"mapcontrols"}>
                <MapZones type={this.props.type} onType={this.props.onType} />
                <MapOverlayZones type={this.props.type} overlayType={this.props.overlayType} onOverlayType={this.props.onOverlayType}/>
                <MapOverlayLayers overlayLayers={this.props.overlayLayers} onOverlayLayer={this.props.onOverlayLayer} />
            </div>
        );
    }
}