import { RegionType } from '../constants/RegionType';

export class APIService {
    static convertTypeToIndex(s) {
        switch (s) {
            case RegionType.REZ: return 3;
            case RegionType.LGA: return 1;
            case RegionType.StateRegion: return 2;
            default: return 0;
        }
    }

    static convertIndexToType(i) {
        switch (i) {
            case 1: return RegionType.LGA;
            case 2: return RegionType.StateRegion;
            case 3: return RegionType.REZ;
            default: return RegionType.LGA;
        }
    }

    _cache;
    _logger;
    _fetching = {};

    constructor(cache, logger) {
        this._cache = cache;
        this._logger = logger;
    }

    getRegionByZoneId(zoneId, onData) {
        let url = 'api/Region/' + zoneId;
        let item = this._cache.get(url);
        if (item) {
            onData(item, null);
        }
        else {
            fetch(url)
                .then(response => response.json())
                .then(function (data) {
                    this._cache.put(url, item);
                    onData(data, null);
                }.bind(this), error => onData(null, error));
        }
    }

    getRegionByName(type, name, onData) {
        let url = 'api/Region/' + APIService.convertTypeToIndex(type) + '/' + name;
        let item = this._cache.get(url);
        if (item) {
            onData(item, null);
        }
        else {
            fetch(url)
                .then(response => response.json())
                .then(function (data) {
                    this._cache.put(url, item);
                    onData(data, null);
                }.bind(this), error => onData(null, error));
        }
    }

    getRegionNames(type, onData) {
        let url = 'api/Regions/names/' + APIService.convertTypeToIndex(type);
        let item = this._cache.get(url);
        if (item) {
            onData(item, null);
        }
        else {
            fetch(url)
                .then(response => response.json())
                .then(function (data) {
                    this._cache.put(url, data);
                    onData(data, null);
                }.bind(this), error => onData(null, error));
        }
    }

    // returns data if available, else calls onData later
    getGeog(id, onData) {
        let url = '/geojson/' + id + '.json';
        let data = this._cache.get(url);
        if (data) return data;

        if (this._fetching[url]) return null;

        this._fetching[url] = true;

        fetch(url)
            .then(response => response.json())
            .then(function (data) {
                this._fetching[url] = false;
                this._cache.put(url, data);
                onData(data, null);
            }.bind(this), error => onData(null, error));
    }
}
