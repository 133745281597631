import React, { Component } from 'react';
import { BusinessCountsChart } from './BusinessCountsChart';
import { EmploymentByIndustryChart } from './EmploymentByIndustryChart';
import { OutputValueAddedChart } from './OutputValueAddedChart';
import { ImportsExportsChart } from './ImportsExportsChart';
import { GRPTrendChart } from './GRPTrendChart';
import { ChangeInEmploymentChart } from './ChangeInEmploymentChart';
import { PopulationTrendChart } from './PopulationTrendChart';
import { ForecastPopulationChart } from './ForecastPopulationChart';
import { UnemploymentRateChart } from './UnemploymentRateChart';
import { AgeCompositionChart } from './AgeCompositionChart';
import { MiscellaneousDemogs } from './MiscellaneousDemogs';
import { SEIFADisadvantageChart } from './SEIFADisadvantageChart';
import { RegionInsightInfoGraphic } from './RegionInsightInfoGraphic';
import { NumberFormatting } from '../helpers/NumberFormatting';

export class EconomyDemography extends Component {
    constructor(props) {
        super(props);

        this.state = { demography: false };
        this.onType = this.onType.bind(this);
    }

    onType() {
        this.setState({ demography: !this.state.demography });
    }

    render() {
        if (this.props.region === null) return null;

        return (
            <div className="economy">
                <div className="container">
                    <h3><span onClick={this.onType} className={!this.state.demography ? " titlechecked" : ""}>Economy</span> & <span onClick={this.onType} className={this.state.demography ? " titlechecked" : ""}>Demography</span></h3>
                    {!this.state.demography &&
                        <div className="pane">
                            <EmploymentByIndustryChart region={this.props.region} />
                        </div>}
                    {!this.state.demography &&
                        <div className="pane">
                            <ChangeInEmploymentChart region={this.props.region} />
                        </div>}
                    {!this.state.demography &&
                        <div className="pane">
                            <OutputValueAddedChart region={this.props.region} />
                        </div>}
                    {!this.state.demography &&
                        <div className="pane">
                            <ImportsExportsChart region={this.props.region} />
                        </div>}
                    {!this.state.demography && (this.props.region.grp.length > 1) &&
                        <div className="pane">
                            <GRPTrendChart region={this.props.region} />
                        </div>}
                    {!this.state.demography && (this.props.region.grp.length === 1) &&
                        <div className="pane">
                        <RegionInsightInfoGraphic title="Gross Regional Product" value={"$" + NumberFormatting.FmtDigitGroups(this.props.region.grp[0] / 1e9, 3) + " B"} icon="/images/icons/grpgraph.png" />
                        </div>}
                    {!this.state.demography &&
                        <div className="pane">
                            <BusinessCountsChart region={this.props.region} />
                        </div>}
                    {this.state.demography &&
                        <div className="pane">
                            <PopulationTrendChart region={this.props.region} />
                        </div>}
                    {this.state.demography &&
                        <div className="pane">
                            <ForecastPopulationChart region={this.props.region} />
                        </div>}
                    {this.state.demography &&
                        <div className="pane">
                            <UnemploymentRateChart region={this.props.region} />
                        </div>}
                    {this.state.demography &&
                        <div className="pane">
                            <AgeCompositionChart region={this.props.region} />
                        </div>}
                    {this.state.demography &&
                        <div className="pane">
                            <MiscellaneousDemogs region={this.props.region} />
                        </div>}
                    {this.state.demography &&
                        <div className="pane">
                            <SEIFADisadvantageChart region={this.props.region} />
                        </div>}

                    <div className="source">
                        {
                            !this.state.demography &&
                            <div>
                                <p>Source:  REMPLAN Economy</p>

                                <p>Incorporating:</p>
                                <ul>
                                    <li>ABS 2021 Census Place of Work Employment (Scaled)</li>
                                    <li>ABS 2020 / 2021 National Input Output Tables</li>
                                    <li>ABS June 2022 Gross State Product</li>
                                    <li>ABS 2021 Census of Population and Housing (Scaled)</li>
                                    <li>ABS 2021 / 2022 Tourism Satellite Account</li>
                                    <li>ABS 2022 Counts of Australian Businesses, including Entries and Exits</li>
                                    <li>TRA 2019 Local Government Area Tourism Profiles</li>
                                </ul>
                            </div>
                        }
                        {
                            this.state.demography &&
                            <div>
                                <p>Source:  REMPLAN Community, ABS Census of Population and Housing, 1991 to 2021 Census years</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
