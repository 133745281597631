export class GeoJsonLayer {
    geogId = null;
    zones = [];
    style = null;
    map = null;
    props = [];
    _logger;

    constructor(encGeog, logger) {
        let i, j;

        this._logger = logger;

        // encGeog is {geogId:"xxx", props:["name",..], zones:[encZone,...]}
        this.geogId = encGeog.geogId;
        this.props = encGeog.props;

        for (i = 0; i < encGeog.zones.length; i++) {
            let encZone = encGeog.zones[i];
            // each zone is [ propsarray, featuresaray ]

            let zone = { props: encZone[0], layer: this, features: [] };
            let encFeatures = encZone[1];

            let points = [];
            let lines = [];
            let polys = [];

            for (j = 0; j < encFeatures.length; j++) {
                let encFeature = encFeatures[j];
                // each feature is a point, line or polygon
                // point is [number,number]
                // line is google encoded string
                // polygon is [outerencodedstring,innerencodedstring,...]
                // detect which one here and build the appropriate feature
                if (typeof encFeature === "string")
                    lines.push(this.decodeLine(encFeature));
                else if (Array.isArray(encFeature))
                    if (typeof encFeature[0] === "string")
                        polys.push(this.decodePoly(encFeature));
                    else if (typeof encFeature[0] === "number")
                        points.push(this.decodePoint(encFeature));
            }

            if (points.length === 1) {
                let feature = new window.google.maps.Data.Feature({ geometry: new window.google.maps.Data.Point(points[0]) });
                feature.setProperty("zone", zone);
                zone.features.push(feature);
            }
            else if (points.length > 1) {
                let feature = new window.google.maps.Data.Feature({ geometry: new window.google.maps.Data.MultiPoint(points) });
                feature.setProperty("zone", zone);
                zone.features.push(feature);
            }

            if (lines.length === 1) {
                let feature = new window.google.maps.Data.Feature({ geometry: new window.google.maps.Data.LineString(lines[0]) });
                feature.setProperty("zone", zone);
                zone.features.push(feature);
            }
            else if (lines.length > 1) {
                let feature = new window.google.maps.Data.Feature({ geometry: new window.google.maps.Data.MultiLineString(lines) });
                feature.setProperty("zone", zone);
                zone.features.push(feature);
            }

            if (polys.length === 1) {
                let feature = new window.google.maps.Data.Feature({ geometry: new window.google.maps.Data.Polygon(polys[0]) });
                feature.setProperty("zone", zone);
                zone.features.push(feature);
            }
            else if (polys.length > 1) {
                let feature = new window.google.maps.Data.Feature({ geometry: new window.google.maps.Data.MultiPolygon(polys) });
                feature.setProperty("zone", zone);
                zone.features.push(feature);
            }

            this.zones.push(zone);
        }
    }

    decodePoint = function (encPoint) {
        return new window.google.maps.LatLng(encPoint[0], encPoint[1]);
    }

    decodeLine = function (encLine) {
        return window.google.maps.geometry.encoding.decodePath(encLine);
    }

    decodePoly = function (encPoly) {
        let polygon = [], j;
        for (j = 0; j < encPoly.length; j++)
            polygon.push(window.google.maps.geometry.encoding.decodePath(encPoly[j]));
        return polygon;
    }

    setMap(map) {
        var i, j;

        if (map === this.map) return;

        if (this.map) {
            for (i = this.zones.length - 1; i >= 0; --i) {
                let zone = this.zones[i];
                for (j = zone.features.length - 1; j >= 0; --j)
                    this.map.data.remove(zone.features[j]);
            }
        }

        this.map = map;

        if (this.map) {
            for (i = this.zones.length - 1; i >= 0; --i) {
                let zone = this.zones[i];
                for (j = zone.features.length - 1; j >= 0; --j)
                    this.map.data.add(zone.features[j]);
            }
        }
    }
}
