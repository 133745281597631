import React, { Component } from 'react';

export class GradientChart extends Component {

    makeChartStyle() {
        return {
            backgroundImage: "linear-gradient(to right," + this.props.range.minColor + "," + this.props.range.maxColor + ")"
        };
    }

    makeMarkerStyle(v) {
        return {
            left: ((v - this.props.range.min) / (this.props.range.max - this.props.range.min) * 100) + "%"
        };
    }

    render() {
        return (
            <div className={"gradientchart " + (this.props.className ? this.props.className : "")} style={this.makeChartStyle()}>
                <div className="markerbox">
                    {this.props.range.minLabel && <div className="min">{this.props.range.minLabel}</div>}
                    {this.props.range.maxLabel && <div className="max">{this.props.range.maxLabel}</div>}
                    {this.props.markers.map((v, i) => <div key={i} className="marker" style={this.makeMarkerStyle(v)} />)}
                </div>
            </div>
        );
    }
}