import React, { Component } from 'react';
import { Chart, BarController, LinearScale, CategoryScale, BarElement, Tooltip } from 'chart.js';
import { NumberFormatting } from '../helpers/NumberFormatting';
import { ChartHelper } from '../helpers/ChartHelper';

Chart.register(BarController, LinearScale, CategoryScale, BarElement, Tooltip);
export class AgeCompositionChart extends Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this._gradient = ChartHelper.MakeHorzGradient(this.chartRef.current);

        this._chart = new Chart(this.chartRef.current, {
            type: 'bar',
            options: {
                indexAxis: 'y',
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (context) => "Percent of population",
                            label: (context) => context.label + ": " + NumberFormatting.FmtDigitGroups(context.parsed.x, 1) + "%"
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                maxBarThickness: 11,
                scales: {
                    y: {
                        grid: { display: false },
                        ticks: { autoSkip: false }
                    },
                    x: {
                        beginAtZero: true,
                        ticks: {
                            callback: function (value, index, values) {
                                return NumberFormatting.FmtDigitGroups(value,0) + "%";
                            }

                        }
                    }
                },
            },
            data: this.getChartData()
        });
    }

    getChartData() {
        let data = this.props.region.age.map((v, i) => ({
            name: this.props.region.ageLabels[i],
            percent: v*100}));

        return {
            labels: data.map(v => v.name),
            datasets: [{
                label: 'Lifestage',
                data: data.map(v => v.percent),
                backgroundColor: this._gradient,
            }]
        };
    }

    updateChart() {
        if (this._chart) {
            this._chart.data = this.getChartData();
            this._chart.update();
        }
    }

    render() {
        let height = "10em";
        let style = { minHeight: height, height: height };

        this.updateChart();

        return <div className="agecompositionchart">
            <h5>Age Composition</h5>
            <div className="jschartContainer" style={style}>
                <canvas ref={this.chartRef} />
            </div>
        </div>

    }
}
