import { ImpactType } from '../constants/ImpactType';
import { InsightType } from '../constants/InsightType';
import { RegionType } from '../constants/RegionType';

export class ParsingHelper {
    static mapType(s) {
        switch (s) {
            case RegionType.REZ:
            case RegionType.LGA:
            case RegionType.StateRegion:
                return s;
            default:
                return RegionType.REZ;
        }
    }

    static mapInsight(s) {
        switch (s) {
            case InsightType.Economy:
            case InsightType.Environment:
            case InsightType.Impact:
            case InsightType.Infrastructure:
            case InsightType.REP:
                return s;
            default: return InsightType.REP;
        }
    }

    static mapImpactType(s) {
        switch (s) {
            case ImpactType.Biomass:
            case ImpactType.SolarFarm:
            case ImpactType.SolarRooftop:
            case ImpactType.Wind:
                return s;
            default:
                return ImpactType.SolarFarm;
        }
    }

    static parseMW(s) {
        return s && s.length > 0 && s >= 0 ? parseFloat(s) : undefined;
    }
}