export class CacheService {
    _cache = {};

    constructor(logger) {
        this._logger = logger;
    }

    get(key) {
        var item = this._cache[key];

        return item ? item : null;
    }

    put(key, value) {
        this._cache[key] = value;
    }
}