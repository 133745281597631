import React, { Component } from 'react';

// best to do this old school?

import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import { Config } from '../config/Config';

export class Map extends Component {
    static libraries = ["geometry"];

    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
        this.onMapLoad = this.onMapLoad.bind(this);
    }

    onMapLoad(map) {
        this.props.mapService.setMap(map);
    }

    onSelect(e) {
        this.props.onZoneId(e.target.value);
    }

    render() {
        let options = {
            controlSize: 20,
            styles: this.props.mapService.getMapStyles(),
            fullscreenControl: false,
            minZoom: 4
        };

        return (
            <div className="map">
                <LoadScriptNext googleMapsApiKey={Config.GoogleMapsAPIKey} libraries={Map.libraries}>
                    <GoogleMap
                        id='gmap' mapTypeId='hybrid'
                        mapContainerStyle={{
                            height: "100%",
                            width: "100%"
                        }}                        
                        options={options}
                        onLoad={this.onMapLoad}
                    />
                </LoadScriptNext>
            </div>
        );
    }
}

/*
                 {this.props.names && this.props.names.length > 0
                    ? (<p>Google Map in progress...select a region from here for now <select value={this.props.name} onChange={this.onSelect}><option value="">--Select a region--</option>
                        {this.props.names.map(x => (<option key={x[0]} value={x[0]}>{x[1]}</option>))}
                    </select></p>)
                    : (<p>Region names loading...</p>)}
*/