import React, { Component } from 'react';

export class LegendItem extends Component {

    mapStrokeWeight(w) {
        return w ? (w + "px") : "1px";
    }

    mapColor(c, o) {
        if (c === undefined) return "";

        if (o === undefined) o = 1;

        var bigint = parseInt(c.substring(1), 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;
        return "rgba(" + r + "," + g + "," + b + "," + o + ")";
    }

    renderItem(legend, i) {
        let style = null;
        if (legend.style)
            style = legend.style;
        else if (this.props.item.styles)
            style = this.props.item.styles[i];
        else 
            style = this.props.item.style;

        let divStyle = {
            borderStyle: style.strokeWeight ? "solid" : "none",
            borderWidth: this.mapStrokeWeight(style.strokeWeight),
            borderColor: this.mapColor(style.strokeColor, style.strokeOpacity),
            height: style.fillColor ? "21px" : "0",
            backgroundColor: this.mapColor(style.fillColor, style.fillOpacity)
        };

        let icon = style.icons ? style.icons[0] : style.icon;

        return (<div key={i} className="item"> {icon && <img alt={legend.name} src={icon.url} />}{(style.strokeColor || style.fillColor) && <div style={divStyle} />}{legend.image && <img alt={legend.name} src={legend.image} />}{legend.name && <h5>{legend.name}</h5>} </div>);
    }

    render() {
        return (
            <div className="legenditem">
                <h5>{this.props.item.name}</h5>
                {this.props.item.legendFooter && <div className="footer">{this.props.item.legendFooter}</div>}
                <div className="items">
                    {this.props.item.legend.map((v, i) => this.renderItem(v, i))}
                </div>
                {this.props.item.legendSource && <div className="source">Source: {this.props.item.legendSource}</div>}
            </div>
        );
    }
}