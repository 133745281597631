import React, { Component } from 'react';

import { EconomicImpactInfoGraphic } from './EconomicImpactInfoGraphic';
import { EconomicImpactInputs } from './EconomicImpactInputs';
import { EconomicImpactVicMap } from './EconomicImpactVicMap';
import { NumberFormatting } from '../helpers/NumberFormatting';
import { ParsingHelper } from '../helpers/ParsingHelper';

import { ImpactType } from '../constants/ImpactType';

export class EconomicImpact extends Component {
    constructor(props) {
        super(props);

        this.onType = this.onType.bind(this);
        this.onMW = this.onMW.bind(this);
    }

    onType(type) {
        this.props.onImpact({ mw: this.props.impact.mw, mwvalue: this.props.impact.mwvalue, type: type });
    }

    onMW(value) {
        let value2 = ParsingHelper.parseMW(value);
        if (value2 === undefined) value2 = 0;

        this.props.onImpact({ mw: value, mwvalue: value2, type: this.props.impact.type });
    }

    getImpact(type) {
        switch (type) {
            case ImpactType.Wind: return this.props.region.wind;
            case ImpactType.SolarFarm: return this.props.region.solarFarm;
            case ImpactType.SolarRooftop: return this.props.region.solarRooftop;
            case ImpactType.Biomass: return this.props.region.biomass;
            default: return null;
        }
    }
  
    calculate(mw, type) {
        let impact = this.getImpact(type);

        return {
            investmentTotal: impact.investmentTotal * mw,
            investmentState: impact.investmentState * mw,
            investmentRegional: impact.investmentRegional * mw,
            investmentRegion: impact.constructionDirectOutput * mw,
            constructLocalJobs: impact.constructionJobs * mw,
            constructStateJobs: impact.constructionStateJobs * mw,
            constructLocalRevenue: impact.constructionOutput * mw,
            constructValueAdd: impact.constructionValueAdded * mw,
            operStateRevenue: impact.operationStateRevenue * mw,
            operLocalRevenue: impact.operationOutput * mw,
            operLocalJobs: impact.operationJobs * mw,
            operStateJobs: impact.operationStateJobs * mw,
            operValueAdd: impact.operationValueAdded * mw,
            powerForHomes: impact.reducedCO2PowerForHomes * mw,
            type: type,
            mw: mw
        };
    }

    formatType(type) {
        switch (type) {
            case ImpactType.Biomass: return "Biomass Plant";
            case ImpactType.SolarFarm: return "Solar Farm";
            case ImpactType.SolarRooftop: return "Solar Rooftop Installation";
            case ImpactType.Wind: return "Wind Farm";
            default: return "Unknown";
        }        
    }

    formatType2(type) {
        switch (type) {
            case ImpactType.Biomass: return "Biomass";
            case ImpactType.SolarFarm: return "Solar Farm";
            case ImpactType.SolarRooftop: return "Solar Roof Top";
            case ImpactType.Wind: return "Wind";
            default: return "Unknown";
        }
    }

    getOngoingServices(type) {
        switch (type) {
            case ImpactType.SolarRooftop: return "cleaning and maintenance";
            default: return undefined;
        }
    }

    render() {
        if (this.props.region === null) return null;

        let result = this.calculate(this.props.impact.mwvalue, this.props.impact.type);

        let ongoingServices = this.getOngoingServices(result.type);

        return (
            <div className="impact">
                <h3>Economic Impact</h3>
                <div className="container">
                    <EconomicImpactInputs mw={this.props.impact.mw} onMW={this.onMW} type={this.props.impact.type} onType={this.onType} fmtType={this.formatType(this.props.impact.type)} />                 

                    {result.mw > 0 && (<div className="result">
                        <p>A {NumberFormatting.FmtDigitGroups(result.mw, 0)}MW {this.formatType(result.type)} would typically require an investment of ${NumberFormatting.FmtDigitGroups(result.investmentTotal, 3)} million.  Of this expenditure,
it is estimated that ${NumberFormatting.FmtDigitGroups(result.investmentState, 3)} million would be captured in the Victorian economy, including:</p>
                            <ul>
                                <li>${NumberFormatting.FmtDigitGroups(result.investmentRegional, 3)} million in regional Victoria</li>
                                <li>${NumberFormatting.FmtDigitGroups(result.investmentRegion, 3)} million in {this.props.region.name}.</li>
                        </ul>
                        <p>Once operational, a {NumberFormatting.FmtDigitGroups(result.mw, 0)}MW {this.formatType(result.type)} would typically generate gross annual revenue of ${NumberFormatting.FmtDigitGroups(result.operStateRevenue, 3)} million and provide power for up to {NumberFormatting.FmtDigitGroups(result.powerForHomes, 0)} homes.</p>
                        <p>The overall estimated economic uplift for {this.props.region.name}, during construction, and once operational, is detailed below.  
This includes all direct, as well as flow-on supply-chain and consumption impacts. </p>
                    </div>)}

                    {result.mw > 0 && <EconomicImpactVicMap constructJobs={result.constructStateJobs} operJobs={result.operStateJobs} />}
                    
                    {result.mw > 0 && (<div className="construct"><h5>Short term economic impact over construction phase - {this.props.region.name}</h5>
                        <div className="infographic-container">
                            <EconomicImpactInfoGraphic title="Local Revenue" icon="/images/icons/impactrevenue.svg" value={"$" + NumberFormatting.FmtDigitGroups(result.constructLocalRevenue, 3) + " million"} />
                            <EconomicImpactInfoGraphic title="Local Jobs" icon="/images/icons/impactjobs.svg" value={NumberFormatting.FmtDigitGroups(result.constructLocalJobs, 0)} />
                            <EconomicImpactInfoGraphic title="Local Value Added" icon="/images/icons/impactvalueadded.svg" value={"$" + NumberFormatting.FmtDigitGroups(result.constructValueAdd, 3) + " million"} />
                        </div>
                    </div>)}
                    {result.mw > 0 && (<div className="oper"><h5>Ongoing economic impacts once operational - {this.props.region.name} {ongoingServices && <><br/>({ongoingServices})</>}</h5>
                        <div className="infographic-container">
                            <EconomicImpactInfoGraphic title="Local Revenue" icon="/images/icons/impactrevenue.svg" value={"$" + NumberFormatting.FmtDigitGroups(result.operLocalRevenue, 3) + " million"} />
                            <EconomicImpactInfoGraphic title="Local Jobs" icon="/images/icons/impactjobs.svg" value={NumberFormatting.FmtDigitGroups(result.operLocalJobs, 0)} />
                            <EconomicImpactInfoGraphic title="Local Value Added" icon="/images/icons/impactvalueadded.svg" value={"$" + NumberFormatting.FmtDigitGroups(result.operValueAdd, 3) + " million"} />
                        </div>
                    </div>)}
                    {result.mw === 0 && (<div className="result">No MW of generation was entered</div>)}
                    <div className="source">
                        <p>Source: REMPLAN Economy</p>
                        <p>Incorporating:</p>
                        <ul><li>ABS 2021 Census Place of Work Employment (Scaled)</li>
                            <li>ABS 2020 / 2021 National Input Output Tables</li>
                            <li>ABS June 2022 Gross State Product</li>
                            <li>ABS 2021 Census of Population and Housing (Scaled)</li>
                            <li>ABS 2021 / 2022 Tourism Satellite Account</li>
                            <li>ABS 2022 Counts of Australian Businesses, including Entries and Exits</li>
                            <li>TRA 2019 Local Government Area Tourism Profiles</li>
                        </ul>
                        <br/>
                        <p>Using Australia Bureau of Statistics (ABS) National Accounts and Census datasets, the regional economic modelling software REMPLAN Economy, has been applied to generate region-specific industrial economic data estimates. Data and insights regarding the capital costs and operational revenues of renewable energy facilities has been sourced from Green Energy Markets, MRA Consulting, the Central Victorian Greenhouse Alliance and the Department of Environment, Land, Water and Planning.</p>
                        <br/>
                        <p>Definitions:</p>
                        <p>Local Revenue – The gross turnover or economic output.</p>
                        <p>Local Jobs – Total estimated employment situated in the selected region, without conversion to full-time equivalent employment.</p>
                        <p>Local Value Added -  Economic value at the margin; calculated by subtracting local expenditure and expenditure on regional imports from the economic output generated.</p>
                    </div>
                </div>
            </div>
        );
    }
}