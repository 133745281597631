import React, { Component } from 'react';

export class RegionBlurb extends Component {
   /* constructor(props) {
        super(props);
    }*/

    render() {
        if (!this.props.region || !this.props.region.urlSmallImage) return null;

        let smallStyle = {
            backgroundImage: "url("+this.props.region.urlSmallImage+")"
        };
        let largeStyle = {
            backgroundImage: "url(" + this.props.region.urlLargeImage + ")"
        };
        
        return (
     
            <div className="regionBlurb containerfw">
                <div className="border"><h1>{this.props.region.name}</h1>{this.props.region.blurb && this.props.region.blurb.map((x, i) => <p key={i}>{x || <React.Fragment>&nbsp;</React.Fragment>}</p>)}</div>
                <div className="largePic" style={largeStyle} />
                <div className="smallPic" style={smallStyle} />
            </div>
        );
    }
}