export class ChartHelper {

    static MakeHorzGradient(canvas) {
        let gradient = canvas.getContext('2d').createLinearGradient(20, 0, 220, 0);
        gradient.addColorStop(0, '#5E745C');
        gradient.addColorStop(.5, '#5E745C'); //??
        gradient.addColorStop(1, '#B9BA92');
        return gradient;
    }

    static MakeVertGradient(canvas) {
        let gradient = canvas.getContext('2d').createLinearGradient(0, 20, 0, 220);
        gradient.addColorStop(1, '#5E745C');
        gradient.addColorStop(.5, '#5E745C'); //??
        gradient.addColorStop(0, '#B9BA92');
        return gradient;
    }

    static BrewerPaired12 = [
        "#a6cee3", "#1f78b4", "#b2df8a", "#33a02c", "#fb9a99", "#e31a1c",
        "#fdbf6f", "#ff7f00", "#cab2d6", "#6a3d9a", "#ffff99", "#b15928"]
}