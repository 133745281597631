import React from 'react';
import { GradientChart } from './GradientChart';

export class SEIFADisadvantageChart extends React.Component {

    render() {
        return <div className="seifadisadvantage"><h5>Disadvantage</h5>
            <GradientChart range={({ min: 889, max: 1077, minLabel: "889 More Disadvantage", maxLabel: "1,077 Less Disadvantage", minColor: "#B9BA92", maxColor: "#365549" })} markers={[this.props.region.seifaIndex]} />
        </div>;
    }
}
