import React, { Component } from 'react';
import { MapControlButton } from './MapControlButton';
import { MapControlsExpand } from './MapControlsExpand';
import { RegionType } from '../constants/RegionType';

export class MapZones extends Component {
    constructor(props) {
        super(props);

        this.state = { expanded: true };

        this.onExpand = this.onExpand.bind(this);
    }

    onExpand(e) {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        return (
            <div className="mapzones">
                <MapControlsExpand checked={this.state.expanded} onClick={this.onExpand} />
                <h3>1: Select a Zone</h3>
                <div className={"pane" + (this.state.expanded ? " expanded" : " collapsed")}>
                    <MapControlButton title="Local Government Areas" isChecked={this.props.type === RegionType.LGA} tag={RegionType.LGA} onClick={this.props.onType} />
                    <MapControlButton title="State Government Regions" isChecked={this.props.type === RegionType.StateRegion} tag={RegionType.StateRegion} onClick={this.props.onType} />
                    <MapControlButton title="Renewable Energy Zones" isChecked={this.props.type === RegionType.REZ} tag={RegionType.REZ} onClick={this.props.onType} />
                </div>
            </div>
        );
    }
}