import React, { Component } from 'react';

export class HeaderBackSoon extends Component {
    render() {
        return (
            <div className="header">
                <div className="mask">
                    <div className="container">
                        {
                            this.props.title
                                ? this.props.title
                                : <h1>Renewable Energy Transition<br />Socio-economic Modelling Tool<br/>Back soon</h1>
                        }
                        <div className="blurb">
                            {
                                <p>Questions? Please call REMPLAN: 1300 737 443</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}